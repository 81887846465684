//@ts-nocheck
import React, { Component } from "react";
import {  withStyles,Theme } from "@material-ui/core";
import MuiPopover from "@material-ui/core/Popover";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

// import closeIcon from '../../blocks/LandingPage2/assets/phase2/close.png'
export interface DialogOptions {
  catchOnCancel?: boolean;
  title: string;
  anchorEl : HTMLButtonElement | null,
  dataToPass: any | null;
  Style?: React.CSSProperties;
  renderedComponent: React.ReactNode;
  width? : string;
  height? : string;
  withCustomDialog? : boolean;
  disableBackdropClick? : boolean,
  disableEscapeKeyDown? : boolean,
  scroll? : string | boolean
}

export interface DialogProps extends DialogOptions {
  open: boolean;
  onSubmit: (data : any) => void;
  onCancel: () => void;
}

const styles = (theme : Theme) => ({
  root: {
    margin: 0,
    padding: "24px 24px 12px 24px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    // position: "absolute",
    // right: theme.spacing(1),
    // top: theme.spacing(1),
    padding: 0
  },
  closeImage: {
    height: "12px",
    width: "12px",
  },
  dialogTitle: {
    fontSize: "18px",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.21,
    letterSpacing: "-0.02em",
    color: '#3C3E49',
  },
  svgRoot : {
    width : '18px',
    height : '18px'
  }
});

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onCancel, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography component="div" className={classes.dialogTitle}>
        {children}
      </Typography>
      {onCancel ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onCancel}
          size="medium"
        >
           <CloseIcon classes={{root: classes.svgRoot}} ></CloseIcon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const Popover = withStyles((theme)=>{return {
   paper: {
    maxWidth: (props) => {
      return props?.width ? props?.width : '30%'
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    borderRadius : 3,
    boxShadow : '0px 5px 12px rgba(0, 0, 0, 0.11)',
    border : '1px solid #D0D2DA'

  },
  root : {

  }
}})((props) => {
  const { children, classes : ab, onCancel,...other } = props;
  return <MuiPopover onClose={onCancel} {...other}  classes={{paper : ab.paper,root : ab.root}}>
      {children}
  </MuiPopover>
})

export const FilterBox: React.FC<DialogProps> = ({
  open,
  title,
  onSubmit,
  dataToPass = {},
  renderedComponent: RenderedComponent,
  onCancel,
  withCustomDialog = false,
  ...rest
}) => {
  return (
    <Popover  onClose={onCancel} open={open} {...rest}>
     {!withCustomDialog && <><DialogTitle onCancel={onCancel}>
        {title}
      </DialogTitle>
      <div style={{overflow : 'hidden',padding : '0'}}>
      {RenderedComponent && (
        <RenderedComponent
          dataToPass={dataToPass}
          onSubmit={onSubmit}
          onCancel={onCancel}
          {...rest}
        />
      )}
    </div></>} 
    </Popover>
  );
};

export const FilterPopoverContext = React.createContext<
  (options: DialogOptions) => Promise<void>
>(Promise.reject);

export const FilterPopoverProvider = ({ children }) => {
  const [popoverState, setPopoverState] = React.useState<DialogOptions | null>(
    null
  );

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openFilter = (options: DialogOptions) => {
    setPopoverState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleCancel = () => {
    if (popoverState?.catchOnCancel && awaitingPromiseRef?.current) {
      awaitingPromiseRef.current.resolve();
    }
    setPopoverState(null);
  };

  const handleSubmit = (data: any) => {
    if (awaitingPromiseRef?.current) {
      awaitingPromiseRef?.current?.resolve(data);
    }
    setPopoverState(null);
  };

  return (
    <FilterPopoverContext.Provider value={openFilter}>
      {children}
      <FilterBox
        open={Boolean(popoverState?.anchorEl)}
        anchorEl={popoverState?.anchorEl}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        marginThreshold={-54}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...popoverState}
      />
      
    </FilterPopoverContext.Provider>
  );
};
export default FilterPopoverProvider;