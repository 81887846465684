import React from "react";
import {
  Category as CategoryType,
  EmailSettings as EmailSettingsType,
  EmailSettingsMap,
} from "./Types";
import Switch from "@material-ui/core/Switch";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";

interface Props {
  categories: CategoryType[];
  onActiveChange: (id: number, newCheckedStatus: boolean) => void;
  onEditClick: (id: number) => void;
  emailSettings: EmailSettingsMap;
}

export default function ({
  categories,
  onActiveChange,
  emailSettings,
  onEditClick,
}: Props) {
  return (
    <div className="categories">
      {categories.map((category, categoryIndex) => (
        <Category
          key={category.category_name + categoryIndex}
          data={category}
          onActiveChange={onActiveChange}
          onEditClick={onEditClick}
          emailSettings={emailSettings}
        />
      ))}
    </div>
  );
}

export const Category = ({
  data,
  onActiveChange,
  onEditClick,
  emailSettings,
}: {
  data: CategoryType;
  onActiveChange: (id: number, newCheckedStatus: boolean) => void;
  onEditClick: (id: number) => void;
  emailSettings: EmailSettingsMap;
}) => {
  return (
    <div className="category">
      <span className="category-name">{data.category_name}</span>
      <div className="email-settings">
        {data.email_settings.map((setting) => (
          <EmailSettings
            key={setting.id}
            emailSettings={emailSettings[Number(setting.id)]}
            onActiveChange={onActiveChange}
            onEditClick={onEditClick}
          />
        ))}
      </div>
    </div>
  );
};

interface EmailSettingsProps {
  emailSettings: EmailSettingsType;
  onActiveChange: (id: number, newCheckedStatus: boolean) => void;
  onEditClick: (id: number) => void;
}

export const EmailSettings = ({
  emailSettings,
  onActiveChange,
  onEditClick,
}: EmailSettingsProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onActiveChange(emailSettings.id, event.target.checked);
  };

  const handleEditClick = () => {
    onEditClick(emailSettings.id);
  };

  return (
    <div className="email-setting-box">
      <div className="email-setting-wrapper">
        <div className="email-setting-left">
          <span>{emailSettings.title}</span>
        </div>
        <div className="email-setting-right">
          <i
            className="icon-pencil edit-icon"
            onClick={handleEditClick}
            data-testid={"email-edit-" + emailSettings.id}
          />
          <IOSSwitch checked={emailSettings.active} onChange={handleChange} />
          <span className="status">
            {emailSettings.active ? "Active" : "Inactive"}
          </span>
        </div>
      </div>
    </div>
  );
};

interface SwitchProps {
  classes: any;
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: any) => void)
    | undefined;
  checked: boolean;
}

const IOSSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 48,
      height: 24,
      padding: 0,
      margin: 0,
    },
    switchBase: {
      padding: 0,
      "&$checked": {
        transform: "translateX(29px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#00D659",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#00D659",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 18,
      height: 18,
      marginTop: 3,
    },
    track: {
      borderRadius: 24 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[400],
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(({ classes, onChange, checked, ...props }: SwitchProps) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      onChange={onChange}
      checked={!!checked}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
