import { BlockComponent } from "../../../../framework/src/BlockComponent";

// Customizable Area Start
// Customizable Area End

export type Props = {
  // Customizable Area Start
  children?: JSX.Element;
  title: string;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class HeaderController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
  }
}
