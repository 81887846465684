import { BlockComponent } from "../../../../framework/src/BlockComponent";

// Customizable Area Start
import { Payment } from "../Types";
// Customizable Area End

export type Props = {
  // Customizable Area Start
  open: boolean;
  setOpen: (newState: boolean) => void;
  onSubmit: (data: object) => void;
  defaultValues?: Payment;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentActivateDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
  }
}
