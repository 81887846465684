/* global HTMLButtonElement */
import React, { useRef } from "react";
import MobileAppController from "./MobileAppController.web";
import withLoader from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import withHeaderActionBar from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { Dialog } from "../../studio-store-ecommerce-components/src/Dialog/Dialog";
import { withDialog } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
import { ButtonWithDialog } from "../../studio-store-ecommerce-components/src/ButtonWithDialog/ButtonWithDialog";
import { Formik, Form } from "formik";
import {
  Header,
  Tabs,
  SampleDocuments,
  AppRequirementForm,
  Schema,
  RadioInput,
  TextInput,
  DownloadJson,
} from "./Utilities/Utils";
import "./styles.css";
import { Button } from "@material-ui/core";
import * as Yup from "yup";
import { QrCode as QrCodeType } from "./Types";
import { QRCodeSVG } from "qrcode.react";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import AppSettingsForm from "./Utilities/AppSettingsForm";

const configJSON = require("./config");

export class MobileApp extends MobileAppController {
  render() {
    const { selectedTab, initialValues, qrCodes } = this.state;

    const getTabContent = () => {
      if (selectedTab === 0) {
        return configJSON.appSettingsHint;
      }
      return selectedTab === 2
        ? configJSON.qrCodeHint
        : configJSON.appRequirementHint;
    };

    const renderTabs = () => {
      if (selectedTab === 1) {
        return (
          <div className="app-requirement">
            <Formik
              // @ts-ignore
              innerRef={this.formRef}
              initialValues={initialValues}
              validationSchema={Schema}
              onSubmit={this.handleSubmit}
              enableReinitialize
            >
              {(formikProps) => {
                return (
                  <AppRequirementForm
                    onCountryChange={this.handleCountryChange}
                    onStateChange={this.handleStateChange}
                    onCityStateChange={this.handleCityStateChange}
                    state={this.state}
                    showHeaderBar={this.showHeaderBar}
                    handleClickNew={this.handleClickNew}
                    formikProps={formikProps}
                  />
                );
              }}
            </Formik>
            <div className="flex flex-row">
              <SampleDocuments />
              <DownloadJson />
            </div>
          </div>
        );
      } else if (selectedTab === 2) {
        return (
          <div className="qr-codes">
            {!qrCodes.length ? (
              <div>
                Currently there are no QR codes generated. Please generate QR code.
              </div>
            ) : (
              <>
                {qrCodes.map((code) => (
                  <QrCode
                    key={code.id}
                    {...code}
                    onDelete={this.deleteQrCode}
                    onClickEdit={this.showEditQrCodeDialog}
                  />
                ))}
              </>
            )}
          </div>
        );
      } else {
        return <AppSettingsForm />;
      }
    };

    return (
      <Scrollbars>
        <div className="mobile-app">
          <Header
            onClick={this.showGenerateQrCodeDialog}
            showQrCodeButton={selectedTab === 2}
            disabled={qrCodes.length === 2}
          >
            <div className="tabs-wrapper">
              <Tabs value={selectedTab} onTabChange={this.handleTabChange} />
            </div>
          </Header>
          <div className="content">
            <span className="mobile-app-tab-hint">{getTabContent()}</span>
            {renderTabs()}
          </div>
          <GenerateQrCodeDialog
            open={this.state.showQrCodeDialog}
            setOpen={this.setShowQrCodeDialog}
            onSubmit={this.generateQrCode}
            id={this.state.selectedQrCodeId}
            initialValues={
              this.state.selectedQrCodeId
                ? this.state.qrCodes.find(
                    (code) => code.id === this.state.selectedQrCodeId
                  )
                : InitialValues
            }
          />
        </div>
      </Scrollbars>
    );
  }
}

type QrCodeProps = QrCodeType & {
  onDelete: (id: number) => void;
  onClickEdit: (id: number) => void;
};

export const QrCode = ({
  id,
  code_type,
  url,
  updated_at,
  onDelete,
  onClickEdit,
}: QrCodeProps) => {
  const deviceType = code_type === "ios" ? "iOS" : "Android";

  const handleEdit = () => {
    onClickEdit(id);
  };

  const handleDelete = () => {
    onDelete(id);
  };

  return (
    <div className="qr-code-card-wrapper">
      <div className="qr-code-card">
        <div className="qr-code-image">
          <QRCodeSVG value={url} />
        </div>
        <div className="qr-code-type">{deviceType}</div>
        <span className="qr-code-title">
          {deviceType} {configJSON.qrCode}
        </span>
        <div className="qr-code-updated-at">
          {configJSON.updatedAt} {moment(updated_at).format("DD/MM/YY")}
        </div>
        <Button
          className="edit-qr-code-button"
          variant="outlined"
          onClick={handleEdit}
        >
          {configJSON.editQrCode}
        </Button>
        <ButtonWithDialog
          className="delete-qr-code-button"
          containerClassName="mobile-app-dialog"
          okClassName="error-button"
          title="Delete Qr Code"
          message="Are you sure to delete Qr code?"
          okay="Yes, delete"
          onSubmit={handleDelete}
        >
          {configJSON.deleteQrCode}
        </ButtonWithDialog>
      </div>
    </div>
  );
};

type GenerateQrCodeDialog = {
  open: boolean;
  setOpen: (newState: boolean) => void;
  onSubmit: (data: any) => void;
  id: number;
  initialValues: any;
};

const InitialValues = {
  code_type: "android",
  url: "",
};

export const GenerateQrCodeDialog = ({
  open,
  setOpen,
  onSubmit,
  initialValues,
  id,
}: GenerateQrCodeDialog) => {
  const qrCodeRef = useRef(null);

  const handleSubmitDialog = () => {
    // @ts-ignore
    qrCodeRef.current.handleSubmit();
    return false;
  };

  const handleSubmit = (data: any) => {
    onSubmit(data);
  };
  return (
    <Dialog
      containerClassName="create-qr-code-dialog"
      title={id ? "Edit QR Code" : "Create QR code"}
      titleClassName="create-qr-dialog-title"
      customContent
      open={open}
      setOpen={setOpen}
      onSubmit={handleSubmitDialog}
      okay={id ? "Update" : "Save"}
    >
      <Formik
        innerRef={qrCodeRef}
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          code_type: Yup.string().required("This field required"),
          url: Yup.string()
            .url("Must be a valid url")
            .required("This field required"),
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {(formikProps) => {
          return (
            // @ts-ignore
            <Form autoComplete="off" noValidate className="form">
              <span className="app-type-title">{configJSON.appType}</span>
              <RadioInput
                {...formikProps}
                name="code_type"
                options={[
                  { label: configJSON.labelAndroid, value: "android" },
                  { label: configJSON.labelIOs, value: "ios" },
                ]}
              />
              <div className="mt-30">
                <TextInput label="Url" name="url" required {...formikProps} />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

type GenerateQrCodeButtonProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

export const GenerateQrCodeButton = ({
  onClick,
  disabled,
}: GenerateQrCodeButtonProps) => {
  return (
    <Button
      className="generate-qr-code-button"
      variant="outlined"
      onClick={onClick}
      disabled={disabled}
    >
      {configJSON.createQrCode}
    </Button>
  );
};

export default withDialog(withHeaderActionBar(withLoader(MobileApp)));
