/* global HTMLButtonElement */
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { dialogBoxProps } from "../../studio-store-ecommerce-components/src/HOC/withDialog.web";
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
// @ts-ignore
import { NotificationFormatted } from "./Types";
import { withHeadeActionBarProps } from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { withToastProps } from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";

const configJSON = require("./config");

export type Props = dialogBoxProps &
  withHeadeActionBarProps &
  withToastProps &
  dialogBoxProps &
  withLoaderProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    showHeaderBar: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  pageInitialized: boolean;
  page: number;
  allNotifications: NotificationFormatted[];
  showNotificationCreate: boolean;
  [key: string]: any;
  totalCount: number;
  checkedNotificationList: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

class NotificationsController extends BlockComponent<Props, S, SS> {
  initMessageId: string = "";
  deleteMessageId: string = "";
  editMessageId: string = "";
  createMessageId: string = "";
  getNotificationsApiCallId: string = "";
  postBulkDeleteCallId: string = "";
  sendNotificationApiCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      pageInitialized: false,
      page: 1,
      // notifications: {},
      allNotifications: [],

      showNotificationCreate: false,

      per_page: 30,
      totalCount: 0,
      checkedNotificationList: [],
      isEditClicked: false,
      notificationItem: undefined,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.initPage();
    this.getNotificationsDataApi();
  }

  handleCreateClick = () => {
    this.setState({ isEditClicked: false });
    this.handleCreateDialogState(true);
  };

  handleCreateDialogState = (newState: boolean) => {
    this.setState({
      showNotificationCreate: newState,
    });
  };

  updateField = (name: string, value: any) => {
    this.setState({
      [name]: value,
    });
  };

  handleEditClick = (item: any) => {
    this.setState({
      isEditClicked: true,
      notificationItem: item,
    });
    this.handleCreateDialogState(true);
  };

  handlePushClick = (data: any) => {
    const requestMessage = this.generateRequestMessage(
      `${configJSON.notificationAPiEndPoint}/${data.id}/${configJSON.sendNotification}`,
      configJSON.getNotificationApiMethodType
    );
    this.sendNotificationApiCallId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  handlePageChange = (value: number) => {
    this.setState(
      {
        page: value,
      },
      () => {
        this.scrollToTop();
        this.getNotificationsDataApi();
      }
    );
  };

  checkedItems = (item: any) => {
    this.setState({ ...this.state, checkedNotificationList: item });
  };

  handleDeleteModal = () => {
    // @ts-ignore
    this.props.setDialogState(true, {
      title: configJSON.areYouSure,
      message: configJSON.areYouSureYouWantToDeleteThisNotifications,
      confirmColor: "white",
      confirmBackground: "#FF1744",
      confirmHoverBackground: "rgb(240, 25, 73)",
      onSubmit: () => this.handleBulkDeleteNotificationAPI(),
    });
  };
  handleBulkDeleteNotificationAPI = async () => {
    const { checkedNotificationList } = this.state;
    let onlyIDs = checkedNotificationList?.map((element: any) => +element.id);
    await this.postBulkDelete(onlyIDs);
  };

  initPage = () => {
    const requestMessage = this.generateRequestMessage(
      configJSON.notificationAPiEndPoint,
      configJSON.getNotificationApiMethodType
    );
    this.initMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  handleCreateNotification = (data: any) => {
    const requestMessage = this.generateRequestMessage(
      configJSON.notificationAPiEndPoint,
      configJSON.createNotificationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    this.createMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };
  handleError = (title: any, responseJson: any) => {
    this.showError(
      title,
      responseJson,
      this.props.hideLoader,
      // @ts-ignore
      this.props.setDialogState
    );
  };

  handleEditNotification = (data: any) => {
    const requestMessage = this.generateRequestMessage(
      `${configJSON.notificationAPiEndPoint}/${data.id}`,
      configJSON.updateNotificationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        title: data.title,
        message: data.message,
      })
    );
    this.editMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.initMessageId === apiRequestCallId) {
        this.setState({ pageInitialized: true });
        if (responseJson.errors) {
          return this.handleError(
            configJSON.errorOnPageInitialization,
            responseJson
          );
        }
        const allNotifications =
          responseJson.data?.map(
            (item: any) =>
              ({
                id: item.attributes.id,
                title: item.attributes.title,
                message: item.attributes.message,
              } as NotificationFormatted)
          ) || [];

        const totalCount = responseJson?.meta?.pagination?.total_count || 0;
        this.setState({
          allNotifications,
          totalCount: totalCount,
        });
        this.props.hideLoader();
      }

      if (this.deleteMessageId === apiRequestCallId) {
      } else if (this.postBulkDeleteCallId === apiRequestCallId) {
        if (responseJson && responseJson?.message) {
          this.props.showHeaderBar({ type: "success" });
          this.getNotificationsDataApi();
          this.setState({ ...this.state, checkedNotificationList: [] });
        } else if (responseJson?.errors) {
          return this.props.showHeaderBar({
            message: configJSON.somethingWentWrong,
            type: "error",
          });
        }
      }
      if (
        this.createMessageId === apiRequestCallId ||
        this.editMessageId === apiRequestCallId
      ) {
        this.setState({
          showNotificationCreate: false,
        });
        this.getNotificationsDataApi();
        this.props.hideLoader();
      }

      if (this.sendNotificationApiCallId === apiRequestCallId) {
        if (responseJson.errors) {
          return this.handleError(
            configJSON.somethingWentWrong,
            responseJson
          );
        }
        this.props.hideLoader();
        this.props.showHeaderBar({ 
          type: "success", 
          message: "Notification has been sent" 
        });
      }
    }
  };
  getNotificationsDataApi = () => {
    this.props.displaySpinner();
    const requestMessage = this.generateRequestMessage(
      `${configJSON.notificationAPiEndPoint}?page=${this.state.page}&per_page=${this.state.per_page}`,
      "GET"
    );
    this.initMessageId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  postBulkDelete = async (data: any) => {
    this.props.showLoader();
    const requestMessage = this.generateRequestMessage(
      configJSON.bulkDeleteAPiEndPoint,
      configJSON.bulkDeleteAPiMethodType
    );
    let body = {
      action_type: "DELETE",
      resource: "push_notifications",
      ids: data,
    };
    this.postBulkDeleteCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
}

export default NotificationsController;
