import * as React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './assets/css/style.css';

interface CircularProgressBarProps {
    percentage: number
}

export const CircularProgressBar: React.FunctionComponent<CircularProgressBarProps> = (props) => {
    const { percentage } = props;
    return (
        <div className='circular-progress-bar circular-progress-bar--size-sm circular-progress-bar--theme-grey'>
            <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
            />
        </div>
    );
}