import React from "react";

interface CardProps {
  title: string;
  children?: React.ReactNode;
}

export const Card = ({ title, children }: CardProps) => {
  return (
    <div className="input-card">
      <span className="input-card-title">{title}</span>
      {children}
    </div>
  );
};
