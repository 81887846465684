import * as React from 'react';
import './assets/css/style.css';

interface CircleBadgeProps {
    label: string
}

export const CircleBadge: React.FunctionComponent<CircleBadgeProps> = (props) => {
    const { label } = props
    return (
        <div className='circle-badge circle-badge--size-sm'>
            <span>
                {label}
            </span>
        </div>
    );
}