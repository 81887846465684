import React from "react";
import { InputAdornment } from '@material-ui/core';
import ColorPicker from "material-ui-color-picker";
import { AppSettingsFormController } from "./AppSettingsFormController";
import withHeaderActionBar from "../../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { withDialog } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
import withLoader from "../../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";

const configJSON = require("../config.js");

const colorPickerIcon = require("../assets/colorpicker.svg");

export class AppSettingsForm extends AppSettingsFormController {
  render() {
    const { primaryColor } = this.state;

    return (
      <>
        <div className="store-detail-container address-container justify-content-between">
          <label className="label-store-details">
            {configJSON.primaryAppColor}
          </label>
          <div>
            <ColorPicker
              disabled
              variant="standard"
              value={primaryColor}
              onChange={this.handleCustomPrimaryChange}
              name="primaryColor"
              InputProps={{
                style: {
                  width: "0px",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <img className="icon-color-picker" src={colorPickerIcon} />
                    <section
                      style={{
                        background: primaryColor,
                        height: "35px",
                        width: "35px",
                        borderRadius: "50%",
                        marginLeft: "8px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withDialog(withHeaderActionBar(withLoader(AppSettingsForm)));
