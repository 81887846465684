// Do not change anything in the protected area. Doing so will be detected and your commit will be rejected.

// Protected Area Start
import React, { Component } from 'react';
import { IBlock } from './IBlock';
import { runEngine } from './RunEngine';
import { Message } from './Message';
import MessageEnum, { getName } from './Messages/MessageEnum';
import { Keyboard } from 'react-native';
import * as helper from './Helpers';
import { Stream } from 'stream';
import {Scrollbars} from "react-custom-scrollbars";

export class BlockComponent<Props, S, SS> extends Component<Props, S, SS>
  implements IBlock {
  scrollRef:  React.RefObject<Scrollbars>;
  isLoaded = false;

  send: (message: Message) => void;

  blockId: string;

  subScribedMessages: string[];

  constructor(props: Props) {
    super(props);
    const uuidv4 = require("uuid/v4");
    this.blockId = uuidv4();
    this.send = message => runEngine.sendMessage(this.blockId, message);
    this.subScribedMessages = [''];
    this.hideKeyboard = this.hideKeyboard.bind(this);
    this.scrollRef = React.createRef();
  }

  scrollToTop = (ref?: React.RefObject<Scrollbars>) => {
    (ref || this.scrollRef).current?.scrollToTop();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  receive(from: string, message: Message): void { }

  async componentDidMount() {
    this.isLoaded = true;
  }

  async componentWillUnmount() {
    this.isLoaded = false;
    Keyboard.dismiss();
    runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
  }


  public toggleState(objectID: string) {
    this.changeState(objectID, !Boolean(this.getState(objectID)));
  }

  public changeState(objectID: string, value: any) {
    switch (objectID) {
      default:
        console.log("changeState::Not Confifured for " + objectID);
    }
  }

  public getState(objectID: string) {

    var testString = '';

    switch (objectID) {
      case 'testBoolTrue':
        return true;
      case 'testBoolFalse':
        return false;
      case 'testString':
        return testString;
      default:
        console.log("changeState::Not Confifured for " + objectID);
        return null
    }
  }

  public processOnClickMessage(messageID: string, value: any = null) {
    switch (messageID) {
      default:
        console.log("processOnClickMessage::Not Configured for " + messageID);
    }
  }

  public showAlert(
    title: string,
    error: string,
    btnPositiveText?: string,
    btnPositiveMessage?: Message,
    btnNegativeText?: string,
    btnNegativeMessage?: Message,
    btnNeutralText?: string,
    btnNeutralMessage?: Message
  ) {
    Keyboard.dismiss();

    if (!btnPositiveText && !btnNegativeText && !btnNeutralText) {
      btnPositiveText = 'Ok';
    }

    const alertMsg: Message = new Message(getName(MessageEnum.AlertMessage));
    alertMsg.addData(getName(MessageEnum.AlertTitleMessage), title);
    alertMsg.addData(getName(MessageEnum.AlertBodyMessage), error);
    alertMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    alertMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    alertMsg.addData(
      getName(MessageEnum.AlertButtonPositiveText),
      btnPositiveText
    );
    alertMsg.addData(
      getName(MessageEnum.AlertButtonNegativeText),
      btnNegativeText
    );
    alertMsg.addData(
      getName(MessageEnum.AlertButtonNeutralText),
      btnNeutralText
    );

    alertMsg.addData(
      getName(MessageEnum.AlertButtonPositiveMessage),
      btnPositiveMessage
    );
    alertMsg.addData(
      getName(MessageEnum.AlertButtonNegativeMessage),
      btnNegativeMessage
    );
    alertMsg.addData(
      getName(MessageEnum.AlertButtonNeutralMessage),
      btnNeutralMessage
    );

    runEngine.sendMessage(alertMsg.id, alertMsg);
  }

  public parseApiErrorResponse(responseJson: any) {
    if (!responseJson || !responseJson.errors) {
      return;
    }
    const errors: any[] = responseJson.errors;

    let allerrors = '';
    errors.map((object: string) => {
      const newLocal = JSON.stringify(object);
      JSON.parse(newLocal, (key, value) => {
        if (value.length > 0) {
          if (allerrors.length <= 0) {
            allerrors = value;
          } else {
            allerrors = `${allerrors}{\n}${value}`;
          }
        }
      });
    });

    this.showAlert('Error', allerrors);
    return allerrors;
  }

  parseExpireTokenResponse(responseJson: any, state: any, props: any) {
    if (responseJson?.errors) {
      if (!state.invalidTokenMessageRecieved) {
        if (
          Array.isArray(responseJson?.errors) &&
          (responseJson?.errors.length > 0) &&
          responseJson?.errors[0]?.token &&
          (
            responseJson?.errors[0]?.token == "Token has Expired" ||
            responseJson?.errors[0]?.token == "Invalid token"
          )
        ) {
          return false
        }
        return true
      }
    }
    return true
  }
  logoutAndRedirectToLoginPage = (props : any) => {
     localStorage.clear()
    props?.history?.push({ pathname: '/login', state: { currentPathName : props?.history?.location?.pathname,currentPathState : props?.history?.location?.state }});
 }

  public isPlatformWeb() {
    return helper.getOS() === 'web';
  }

  public isPlatformiOS() {
    return helper.getOS() === 'ios';
  }

  public isPlatformAndroid() {
    return helper.getOS() === 'android';
  }

  public parseApiCatchErrorResponse(errorReponse: any) {
    if (errorReponse) {
      return JSON.stringify(errorReponse).replace(new RegExp('"', 'g'), '')
    }
  }

  public hideKeyboard() {
    if (!this.isPlatformWeb()) {
      Keyboard.dismiss();
    }
  }

  public checkResponseError = (responseJson: any) => {
    return !responseJson || responseJson?.errors || responseJson?.error
  }

  public showError = (
      title: any,
      responseJson: any,
      hideLoader: any,
      setDialogState: any
  ) => {
    hideLoader?.();
    const errors = responseJson?.error || responseJson?.errors || "An error occurred during connection to the server";
    const isOnlyOneString = typeof errors === 'string';
    const stringErrors = typeof errors[0] === 'string';
    setDialogState?.(true, {
      title: title,
      message: isOnlyOneString
          ? errors
          : stringErrors
              ? errors.join('\n')
              : errors
                  .map((i: any) => Object.values(i))
                  .flat()
                  .join('\n'),
      confirmColor: 'white',
      confirmBackground: '#FF1744',
      confirmHoverBackground: 'rgb(240, 25, 73)',
      hideCancel: true,
    });
  };

  public generateRequestMessage = (endpoint: any, type: any, additionalHeaders?: any) => {
    const headers = {
      "content-type": "application/json",
      token: typeof window !== 'undefined' && localStorage.getItem("admintoken") ,
      ...(additionalHeaders || {}),
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    const addSlash = !endpoint.startsWith("/") && !endpoint.startsWith("http");
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        addSlash ? `/${endpoint}` : endpoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        type
    );
    return requestMessage;
  };
}

// Protected Area End
