import React from "react";
import { Button } from "@material-ui/core";
import DownloadJsonController from "./DownloadJsonController";

const configJSON = require("../config");

export class DownloadJson extends DownloadJsonController {
  render() {
    return (
      <div className="app-json-documents">
        <div className="title">{configJSON.downloadJson}</div>
        <div className="button-wrapper">
          <Button
            className="json-button"
            variant="outlined"
            size="small"
            onClick={this.handleGetWebJson}
          >
            {configJSON.webJson}
          </Button>

          <Button
            variant="outlined"
            className="json-button"
            size="small"
            onClick={this.handleGetWMobileJson}
          >
            {configJSON.mobileJson}
          </Button>
        </div>
      </div>
    );
  }
}
