import React from "react";

export interface TabletIconProps {
  color?: string;
  size?: number;
  className?: string;
  style?: any;
}

function TabletIcon(props: TabletIconProps) {
  const { color, size, className, style } = props;
  return (
    <span style={style} className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.49609 12.5H13.4961"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.49805 2C2.49805 1.17157 3.16962 0.5 3.99805 0.5H11.998C12.8265 0.5 13.498 1.17157 13.498 2V14C13.498 14.8284 12.8265 15.5 11.998 15.5H3.99805C3.16962 15.5 2.49805 14.8284 2.49805 14V2Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

TabletIcon.defaultProps = {
  color: "#9B9FB1",
  size: 16,
};
export default TabletIcon;
