// @ts-nocheck
import React, { Component } from "react";
import {FilterPopoverContext,DialogOptions} from "../FilterPopup/FIlterPopoverContext.web";
export type FilterProps = {
  openFilter : (obj : DialogOptions) => Promise
}

function withFilterBox(OriginalComponent) {
  return (props) =>  {
    const  openFilter  = React.useContext(FilterPopoverContext);
    return (
          <OriginalComponent {...props} openFilter={openFilter}/>
      );
    
  };
}
export default withFilterBox;
