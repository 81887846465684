import React from "react"
import Grid from "@material-ui/core/Grid"
import * as Yup from "yup"
import { Formik, Form } from "formik"
import { Typography } from "@material-ui/core"
import { SelectInput, TextInput } from "../../../admin-mobile-app/src/Utilities/Utils"
import PackagingDetailsController from "./PackagingDetailsController"
const configJSON = require("../config")

export class PackagingDetails extends PackagingDetailsController {

  render() {
    const { classes, formRef, onSubmit, showHeaderBar, initialValues, packageOptions, country } = this.props
    return (
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
          <Grid item className={classes.leftContainer}>
            <div className={classes.cardContainer}>
              <Formik
                data-testid="packaging-details-form"
                innerRef={formRef}
                initialValues={initialValues}
                validationSchema={country === 'United Kingdom' ||  country === 'United States' ? SchemaUK : Schema }
                onSubmit={onSubmit}
                enableReinitialize>
                {(formikProps) => {
                  const selectedPackage =
                    packageOptions.find((packageOption) => packageOption.value === formikProps.values.package_id) || {}
                  return (
                    <Form autoComplete="off" noValidate translate={undefined}>
                      <Grid container>
                        <Grid item xs={12} style={{ marginBottom: 10 }}>
                          <Typography className={classes.boldHeading}>
                            {configJSON.packagingDetails}
                          </Typography>
                        </Grid>
                      </Grid>
                      {country === 'United Kingdom' || country === 'United States' ? null :
                      <Grid container>
                        <Grid item xs={3}>
                          <TextInput
                            {...formikProps}
                            type="number"
                            name="weight"
                            label="Weight (kg)"
                            required
                            showHeaderBar={showHeaderBar}
                          />
                        </Grid>
                      </Grid>
                    }
                      <Grid container style={{ marginTop: 24 }}>
                        <Grid item xs={3}>
                          <SelectInput
                            {...formikProps}
                            name="package_id"
                            label={configJSON.packageLabel}
                            required
                            options={packageOptions}
                            showHeaderBar={showHeaderBar}
                          />
                        </Grid>
                        <Grid item xs={9} style={{ paddingLeft: 24 }}>
                          <Grid container style={{ gap: 12, alignItems: "flex-end" }}>
                            <Grid item xs={2}>
                              <TextInput
                                {...formikProps}
                                values={selectedPackage}
                                disabled
                                required
                                label={configJSON.dimensionsLabel}
                                name="length"
                                placeholder={configJSON.lengthPlaceholder}
                                options={packageOptions}
                              />
                            </Grid>
                            <Divider />
                            <Grid item xs={2}>
                              <TextInput
                                {...formikProps}
                                values={selectedPackage}
                                disabled
                                name="width"
                                label=" "
                                placeholder={configJSON.widthPlaceholder}
                                options={packageOptions}
                              />
                            </Grid>
                            <Divider />
                            <Grid item xs={2}>
                              <TextInput
                                {...formikProps}
                                values={selectedPackage}
                                disabled
                                name="height"
                                label=" "
                                placeholder={configJSON.heightPlaceholder}
                                options={packageOptions}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )
                }}
              </Formik>
            </div>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
const Divider = () => <span style={{ color: "#3C3E49", marginBottom: 18, fontWeight: 500 }}>X</span>

const Schema = Yup.object().shape({
  weight: Yup.number()
    .required("This field required")
    .min(0.001, "Must be more than 0kg"),
  package_id: Yup.string().required("This field required"),
});

const SchemaUK = Yup.object().shape({
  package_id: Yup.string().required("This field required"),
});
