import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { withLoaderProps } from "../../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import { withHeadeActionBarProps } from "../../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../../studio-store-ecommerce-components/src/Dialog/withDialog";
export const configJSON = require("../config.js");

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  newImageName: string;
  selectedImages: string[];
  selectedRawImages: any[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export const Endpoint = configJSON.endPoint;

class ImageCatalogueController extends BlockComponent<Props, S, SS> {
  uploadMessageId: String = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      newImageName: "",
      selectedImages: [],
      selectedRawImages: [],
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  bulkUpload = () => {
    const requestMessage = this.generateRequestMessage(Endpoint, configJSON.postAPiMethod, {
      "content-type": undefined,
    });

    const formData = new FormData();
    this.state.selectedRawImages.forEach((i) => {
      formData.append("images[]", i);
    });

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    this.uploadMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        this.bulkUpload();
      }
      if (type === "DISCARDCHANGES") {
        window.location.reload();
      }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.uploadMessageId === apiRequestCallId) {
        this.props.hideLoader();
        if (responseJson.errors) {
          return this.handleError(configJSON.errorOnBulkUpload, responseJson);
        }
        this.props.history.push(configJSON.imageCatalogueUrl2);
        this.props.showHeaderBar({ type: "success" });
      }
    }
  };

  async componentWillUnmount(): Promise<void> {
    super.componentWillUnmount();
    this.props.hideHeaderBar();
  }

  handleError = (title: any, responseJson: any) => {
    this.showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  handleBackButtonClick = () => {
    return this.props.history.push(configJSON.imageCatalogueUrl2);
  };

  handleSelectImages = (files: any) => {
    if (files.length) {
      const oldListLength = this.state.selectedRawImages.length;
      const fileArray = Array.from(files);
      const totalSize = [...this.state.selectedRawImages, ...fileArray].reduce(
        // @ts-ignore
        (acc: number, curr) => acc + Number(curr?.size || 0),
        0
      );
      if (totalSize > 50 * 1024 * 1024) {
        return this.props.showHeaderBar({
          message: configJSON.imageSizeValidation,
          type: "error",
        });
      }
      const newImageArray = Array.from({
        length: oldListLength + files.length,
      }).map(() => "");
      this.state.selectedImages.forEach((i, index) => {
        newImageArray[index] = i;
      });

      this.setState({
        selectedImages: newImageArray,
        selectedRawImages: [...this.state.selectedRawImages, ...fileArray],
      });
      fileArray.forEach((i: any, index: number) => {
        const reader = new FileReader();
        reader.onload = () => {
          this.setState((prevState) => {
            const selectedImages = prevState.selectedImages;
            selectedImages[oldListLength + index] = reader.result as string;
            return { selectedImages };
          });
        };
        reader.readAsDataURL(i);
      });
      this.props.showHeaderBar({ message: "" });
    }
  };

  handleDeleteSelectedImage = (index: number) => () => {
    const selectedImages = [...this.state.selectedImages];
    const selectedRawImages = [...this.state.selectedRawImages];
    selectedImages.splice(index, 1);
    selectedRawImages.splice(index, 1);
    this.setState({ selectedImages, selectedRawImages }, () => {
      this.props.showHeaderBar({ message: "" });
    });
  };
}

export default ImageCatalogueController;
