Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.activationStatusLinkBase = "https://staging.cloud-marketplace.builder.ai/api/accounts/";
exports.errorPageInitialisation = "Error on page initialization";
exports.errorCardUpdate = "Error on card update";
exports.basic = "Basic ";
exports.errorActivation = "Error on Activation Status Check";
exports.payment = "UPI Payment";
exports.upiPaymentIntegration = "UPI integration";
exports.stripeIntegration = "Stripe integration";
exports.paymentIntegration = "Payment integration";
exports.thisFieldIsRequired = "This field is required";
exports.labelUpiId = "UPI ID";
exports.labelUpiMerchantName = "Merchant Name";
exports.notActive = "Not active";
exports.active = "Active";
exports.upiPaymentTitle = "UPI Payment";
exports.stripeTitle = "Stripe";
exports.paymentTitle = "Payment";
exports.user = "UPI ID:";
exports.password = "Password:";
exports.dummyPassword = "*************";
exports.useToGetMessage = "Setup UPI ID for your transaction.";
exports.viewDashboardLink = "https://dashboard.razorpay.com/signin?screen=sign_in";
exports.viewDashboard = "View dashboard";
exports.activateAccount = "Activate account";
exports.editAccount = "Edit account";
exports.consoleErrorBase = "Async: Could not copy text: ";

