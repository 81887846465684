import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Option } from "../../admin-mobile-app/src/Utilities/Utils";

// Customizable Area Start
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import { dialogBoxProps } from "../../studio-store-ecommerce-components/src/HOC/withDialog.web";
import { RouterProps } from "react-router";
import { DialogProps } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
import React from "react";
import { withHeadeActionBarProps } from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { capitalize } from "lodash";

// Customizable Area End

export const configJSON = require("./config");

export type Props = withHeadeActionBarProps &
  DialogProps &
  RouterProps &
  withLoaderProps &
  dialogBoxProps & {
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  orderDetail: any;
  delivery_addresses: any;
  orderId: number | any;
  orderStatus: string;
  packagingDetails: any;
  packageOptions: Option[];
  brandingData: any,
  state: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OrderDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  ordersDataApiCallId: string = "";
  orderShipMessageId: string = "";
  updateDeliveryAddressApiCallId: string = "";
  updatOrderApiCallId: string = "";
  packageListMessageId: string = "";
  brandSettingDataApiCallId: string = '';
  formRef: React.RefObject<unknown>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.formRef = React.createRef();

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];
    this.state = {
      orderDetail: {},
      // @ts-ignore
      orderId: this.props?.match?.params?.id,
      delivery_addresses: {},
      packagingDetails: { ...PackagingDetailsInitialValues },
      packageOptions: [],
      orderStatus: "placed",
      brandingData: null,
      state: null,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getBrandSettings();
    // @ts-ignore
    const orderId = this.props?.match?.params?.id;
    if (orderId) {
      this.getPackages();
      this.getOrderDetail();
    }
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.parseExpireTokenResponse(responseJson, this.state, this.props)) {
        if (apiRequestCallId !== null) {
          if (apiRequestCallId === this.ordersDataApiCallId) {
            this.props.hideLoader();
            if (responseJson) {
              this.handleOrderDetailResponse(responseJson, false);
            } else {
              this.parseApiErrorResponse(responseJson);
            }
          } else if (this.updateDeliveryAddressApiCallId === apiRequestCallId) {
            this.props.hideLoader();
            if(responseJson?.data){
              this.props.showHeaderBar({
                type: "success",
                message: "Successfully updated",
              });
            } else {
              this.props.showHeaderBar({
                type: "error",
                message: "Sorry, Something went wrong",
              });
            }
            this.getOrderDetail();
          } else if (this.updatOrderApiCallId === apiRequestCallId) {
            this.props.hideLoader();
            if (responseJson.error || responseJson.errors) {
              return this.showError(
                "Error on save",
                responseJson,
                this.props.hideLoader,
                this.props.setDialogState
              );
            }
            if (responseJson) {
              this.handleOrderDetailResponse(responseJson, true);
            }
          } else if (this.orderShipMessageId === apiRequestCallId) {
            this.props.hideLoader();
            if (responseJson?.errors || responseJson?.error) {
              this.props.hideLoader();
              let errorMessage = configJSON.shipOrderError;
              if (responseJson?.errors) {
                errorMessage = Array.isArray(responseJson?.errors) ? responseJson?.errors?.join("\n") : responseJson?.errors;
              } else if (responseJson?.error) {
                errorMessage = responseJson?.error;
              }
              return this.props.setDialogState(true, {
                title: configJSON.shipOrderError,
                message: errorMessage,
                confirmColor: "white",
                confirmBackground: "#FF1744",
                confirmHoverBackground: "rgb(240, 25, 73)",
                hideCancel: true,
              });
            } else {
              this.props.showHeaderBar({
                type: "success",
                message: configJSON.orderShippedSuccessfully,
              });
            }
            if (responseJson) {
              this.getOrderDetail();
            }
          } else if (this.packageListMessageId === apiRequestCallId) {
            this.setState({
              packageOptions: responseJson.data.map((item: any) => ({
                label: item.attributes.name,
                value: item.id,
                height: item.attributes.height,
                length: item.attributes.length,
                width: item.attributes.width,
              })),
            });
          } else if (this.brandSettingDataApiCallId === apiRequestCallId) {
            if (responseJson && responseJson?.data) {
              this.setState({ ...this.state, brandingData: responseJson?.data })
            }
          }
        }
      } else {
        this.logoutAndRedirectToLoginPage(this.props);
      }
    } else if (getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        // @ts-ignore
        if (!this.formRef.current.dirty) {
          // do not update package details
          this.updateOrder({});
        } else {
          // @ts-ignore
          this.formRef.current.handleSubmit();
        }
      } else if (type === "DISCARDCHANGES") {
        this.getOrderDetail();
      }
    }
    // Customizable Area End
  }


  handleTrackShipOrder = (link: string) => {
    window.open(link, '_blank', 'noreferrer');
  }

  handleOrderDetailResponse = (responseJson: any, isUpdate: boolean) => {
    const delivery_addresses =
      responseJson?.data?.attributes?.delivery_addresses?.data?.length > 0 &&
      responseJson?.data?.attributes?.delivery_addresses?.data[0].attributes;
    this.setState(
      {
        orderStatus: responseJson?.data?.attributes?.status,
        orderDetail: responseJson?.data?.attributes,
        packagingDetails: {
          ...PackagingDetailsInitialValues,
          package_id: responseJson.data?.attributes?.package?.data?.id || "",
          weight: responseJson.data?.attributes?.weight || "",
        },
        delivery_addresses,
      },
      () => {
        if (!isUpdate) {
          // @ts-ignore
          this.formRef?.current?.resetForm();
        }
      }
    );
  };
  getPackages = () => {
    const message = this.generateRequestMessage(
      configJSON.getPackagesAPiEndPoint,
      configJSON.getPackagesApiMethodType
    );
    this.packageListMessageId = message.messageId;
    this.send(message);
  };

  getOrderDetail() {
    this.props.showLoader();
    this.props.displaySpinner();
    const getValidationsMsg = this.generateRequestMessage(
      `${configJSON.orderApiEndpoint}/${this.state.orderId}`,
      "GET"
    );
    this.ordersDataApiCallId = getValidationsMsg.messageId;
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  onShip = () => {
    this.props.showLoader();
    this.props.displaySpinner();
    const {country} = this.state.brandingData.attributes;
    const getValidationsMsg = this.generateRequestMessage(
      `/${configJSON.orderApiEndpoint}/${this.state.orderId}/${country === "United Arab Emirates" ? 'send_uae_order_to_shiprocket' : 'send_to_shiprocket'}`,
      configJSON.orderOnShipApiMethodType,
      { "content-type": undefined }
    );
    this.orderShipMessageId = getValidationsMsg.messageId;
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  onStuart = () => {
    this.props.showLoader();
    // @ts-ignore
    this.props.displaySpinner();
    const getValidationsMsg = this.generateRequestMessage(`/admin/v1/orders/${this.state.orderId}/send_to_stuart`, "POST", { "content-type": undefined });
    this.orderShipMessageId = getValidationsMsg.messageId;
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  getBrandSettings = async () => {
    const requestMessage = this.generateRequestMessage('/brand_settings/country_data', "GET");
    this.brandSettingDataApiCallId = requestMessage.messageId;
    this.send(requestMessage);
  }
  updateOrder(values: any) {
    const status = this.state.orderStatus;

    this.props.showLoader();
    this.props.displaySpinner();
    const orderData = {
      status,
      ...values,
    };

    const formData = new FormData();
    Object.entries(orderData).forEach(([formKey, value]: [formKey: any, value: any]) => {
      formData.append(formKey, value);
    });

    const getValidationsMsg = this.generateRequestMessage(
      `${configJSON.orderApiEndpoint}/${this.state.orderId}`,
      configJSON.updateOrderApiMethodType,
      { "content-type": undefined }
    );
    this.updatOrderApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }
  updateDeliveryAddress(addressData: any) {
    this.props.showLoader();
    
    // @ts-ignore
    this.props.displaySpinner();

    const formData = new FormData();
    formData.append('name', addressData.name);
    formData.append('flat_no', addressData.flat_no);
    formData.append('address', addressData.address);
    formData.append('address_line_2', addressData.address_line_2);
    formData.append('zip_code', addressData.zip_code);
    formData.append('phone_number', addressData.phone_number);
    formData.append('city', addressData.city);
    if (typeof (addressData.address_state_id) === "number") {
      formData.append('address_state_id', addressData.address_state_id);
    } else {
      // @ts-ignore
      formData.append('address_state_id', 43);
    }
    formData.append('country', addressData.country);

    const getValidationsMsg = this.generateRequestMessage(
      `${configJSON.orderApiEndpoint}/${this.state.orderId}/update_delivery_address/${this.state.delivery_addresses.id}`,
      configJSON.updateOrderApiMethodType,
      { "content-type": undefined }
    );
    this.updateDeliveryAddressApiCallId = getValidationsMsg.messageId;
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleChangeStatus = (event: any) => {
    this.setState(
      {
        orderStatus: event.target.value,
      },
      () => {
        this.openToastOnChange();
      }
    );
  };

  openToastOnChange = () => {
    this.props.showHeaderBar({ message: "" });
  };

  formSubmit = (values: any) => {
    this.updateOrder(values);
  };

  handleOrderStatus = () => {
    switch (this.state.orderDetail?.status) {
      default:
        return configJSON.initialOrderStatuses;
    }
  };

  getOrderStatusText = (status: any) => {
    switch (status) {
      case "in_transit":
        return "In Transit";
      default:
        return capitalize(status);
    }
  };

  handleClickShipingOrder = (brandData: any) => {
    if (brandData?.country === 'United Kingdom') {
      this.onStuart()
    } else {
      this.onShip()

    }
  }
  handleShipOrder = () => {
    if (this.state.orderDetail?.stuart_tracking_url !== null) {
      return true
    }
    else {
      switch (this.state.orderDetail?.status) {
        case "cancelled":
        case "refunded":
        case "returned":
        case "delivered":
          return true;
        case "confirmed":
          if (this.state.orderDetail?.ship_rocket_order_id) {
            return true;
          } else {
            return false;
          }
        default:
          return false;
      }
    }
  };
  getImageUrl = (orderItem: any) => {
    let image: any;
    if (
      orderItem.catalogue_variant_attachment &&
      orderItem.catalogue_variant_attachment.length > 0
    ) {
      orderItem.catalogue_variant_attachment.map((item: any) => {
        if (item.is_default && item.image_url) {
          image = item.image_url;
        }
      });
    } else if (
      orderItem?.catalogue?.data?.attributes?.catalogue_attachments.length > 0
    ) {
      orderItem.catalogue.data.attributes.catalogue_attachments.map(
        (item: any) => {
          if (item.is_default && item.image_url) {
            image = item.image_url;
          }
        }
      );
      if (!image) {
        image =
          orderItem?.catalogue.data.attributes.catalogue_attachments[0]
            ?.image_url;
      }
    }

    return image;
  };
}

const PackagingDetailsInitialValues = {
  weight: "",
  package_id: "",
};
