import * as React from "react";
import { useState } from "react";
import "./pagination.css";
import { Pagination, usePagination } from "@material-ui/lab";
import {
  withStyles,
  Theme,
  createStyles,
  styled,
} from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
});
type PaginationPropType = {
  onChange: (value: number) => void;
  paginationContainerStyle?: React.CSSProperties;
  count: number;
  shape?: "round" | "rounded";
  pageSize?: number;
  itemText?: string | JSX.Element;
  pageNo?: any;
};
const defaultPageSize = 10;
const PaginationComponent = (props: PaginationPropType) => {
  const {
    count,
    shape,
    pageSize,
    itemText,
    paginationContainerStyle,
    onChange,
    pageNo,
  } = props;
  const [pageNumber, setPageNumber] = useState(1);
  const { items } = usePagination({
    count: Math.ceil(count / (pageSize || defaultPageSize)),
    page: pageNo || pageNumber,
    onChange(event, page) {
      onChange(page);
      setPageNumber(page);
    },
  });
  return (
    <>
      <Box className="progressContainer">
        <Typography className="progressText">
          {` You’ve viewed ${
            count <= (pageSize || defaultPageSize) ||
            (pageNo || pageNumber) === Math.ceil(count / (pageSize || defaultPageSize))
              ? count
              : (pageNo || pageNumber) * (pageSize || defaultPageSize)
          } of ${count} ${itemText}`}
        </Typography>
        <LinearProgress
          className="progress"
          variant="determinate"
          value={
            (100 * (pageNo || pageNumber)) /
            Math.ceil(count / (pageSize || defaultPageSize))
          }
        />
      </Box>
      <div style={paginationContainerStyle} className="paginationContainer">
        <nav>
          <List>
            {items.map(({ page, type, selected, ...item }, index) => {
              let children = null;

              if (type === "start-ellipsis" || type === "end-ellipsis") {
                children = (
                  <button {...item} className="pagination-item">
                    <Typography className="pagination-item-text-disabled">
                      ...
                    </Typography>
                  </button>
                );
              } else if (type === "page") {
                if (page === Math.ceil(count / (pageSize || defaultPageSize))) {
                  children = (
                    <button {...item} className="pagination-item-last-page">
                      <Typography
                        className={
                          selected
                            ? "pagination-item-text-active"
                            : "pagination-item-text"
                        }
                      >
                        {page}
                      </Typography>
                    </button>
                  );
                } else {
                  children = (
                    <button {...item} className="pagination-item">
                      <Typography
                        className={
                          selected
                            ? "pagination-item-text-active"
                            : "pagination-item-text"
                        }
                      >
                        {page}
                      </Typography>
                    </button>
                  );
                }
              } else if (type === "previous") {
                children = (
                  <button
                    {...item}
                    className="pagination-item"
                    style={{ border: "none" }}
                  >
                    <Typography
                      className={
                        item.disabled
                          ? "pagination-item-text-disabled"
                          : "pagination-item-text"
                      }
                    >
                      {"<< Prev"}
                    </Typography>
                  </button>
                );
              } else if (type === "next") {
                children = (
                  <button
                    {...item}
                    className="pagination-item"
                    style={{ border: "none" }}
                  >
                    <Typography
                      className={
                        item.disabled
                          ? "pagination-item-text-disabled"
                          : "pagination-item-text"
                      }
                    >
                      {"Next >>"}
                    </Typography>
                  </button>
                );
              }

              return <li key={index}>{children}</li>;
            })}
          </List>
        </nav>
      </div>
    </>
  );
};
const styles = (theme: Theme) => createStyles({});
PaginationComponent.defaultProps = {
  shape: "rounded" as "rounded",
  itemText: "orders",
};
export default withStyles(styles)(PaginationComponent);
