import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { Dialog, DialogProps } from "../Dialog/Dialog";

type ButtonWithDialogProps = DialogProps & {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;

  [key: string]: any;
};

export const ButtonWithDialog = ({
  onClick,
  children,

  onClose,
  onSubmit,
  title = "Dialog Title",
  message = "Dialog message...",
  okay = "Confirm",
  cancel = "Cancel",
  okClassName = "",
  cancelClassName = "",
  titleClassName = "",
  messageClassName = "",
  containerClassName = "",
  hideCancel = false,
  confirmBackground,
  confirmHoverBackground,
  ...props
}: ButtonWithDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    onClick?.(e);
  };

  return (
    <>
      <Button onClick={handleClick} {...props}>
        {children}
      </Button>
      <Dialog
        open={open}
        setOpen={setOpen}
        onClose={onClose}
        onSubmit={onSubmit}
        title={title}
        message={message}
        okay={okay}
        cancel={cancel}
        hideCancel={hideCancel}
        okClassName={okClassName}
        cancelClassName={cancelClassName}
        titleClassName={titleClassName}
        messageClassName={messageClassName}
        containerClassName={containerClassName}
        confirmBackground={confirmBackground}
        confirmHoverBackground={confirmHoverBackground}
      />
    </>
  );
};
