import { FormikProps } from "formik";
import { RefObject, createRef } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { UpiPayment } from "./Types";
// Customizable Area End

export type Props = {
  // Customizable Area Start
  open: boolean;
  setOpen: (newState: boolean) => void;
  onSubmit: (upiData: UpiPayment) => void;
  defaultValues?: UpiPayment;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UpiPaymentActivateDialogController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  formikRef: RefObject<FormikProps<UpiPayment>>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.formikRef = createRef();
  }

  handleSubmitDialog = () => {
    this.formikRef.current?.handleSubmit();
    return false;
  };

  handleSubmit = (upiData: UpiPayment) => {
    this.props.onSubmit(upiData);
  };
}
