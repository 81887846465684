// @ts-nocheck
import { RouterProps } from 'react-router';

import React, { ReactNode } from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { withLoaderProps } from "./withLoader.Web";
import { withToastProps } from "./withSnackBar.Web";
import { withHeadeActionBarProps } from './withHeadeActionBar.Web';
import { runEngine } from '../../../../framework/src/RunEngine';


interface S {
    countriesHOC: Array<any>,
    countryToFlag:(data: any) => void;
    getLocations:() => void
}

export type Props = RouterProps &
    withHeadeActionBarProps &
    withLoaderProps &
    withToastProps & {
        navigation: any;
        id: string;
        // Customizable Area Start
        history: any;
        // Customizable Area End
    };

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

const withCountries = (WrappedComponent) => {
    return class extends BlockComponent<
        Props,
        S,
        SS>{


        getLocationsDataApiCallId: string = '';

        constructor(props: Props) {
            super(props);
            this.receive = this.receive.bind(this);

            this.subScribedMessages = [
                getName(MessageEnum.RestAPIResponceMessage),
                getName(MessageEnum.AlertMessage),
                getName(MessageEnum.ActionMessageFromToaster)
            ];

            this.state = {
                countriesHOC: []
            }
            runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        }
        componentDidMount(): Promise<void> {
            this.getLocations();
        }

        async receive(from: string, message: Message) {
            if (getName(MessageEnum.RestAPIResponceMessage) === message?.id) {
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );

                const responseJson = message.getData(
                    getName(MessageEnum.RestAPIResponceSuccessMessage)
                );

                if (this.parseExpireTokenResponse(responseJson, this.state, this.props)) {
                    if (apiRequestCallId === this.getLocationsDataApiCallId) {
                        if (responseJson?.data) {
                            const locations = responseJson?.data;
                            this.setState({
                                ...this.state,
                                countriesHOC: locations
                            });
                        } else {
                            this.parseApiErrorResponse(responseJson);
                        }
                    }
                }
            }
        }

        getLocations = () => {
            const getLocationsData = this.generateRequestMessage('/admin/v1/locations/countries', "GET");
            this.getLocationsDataApiCallId = getLocationsData.messageId;
            runEngine.sendMessage(getLocationsData.id, getLocationsData);
        }

        countryToFlag = (isoCode) => {
            if (!isoCode) return null;
            return typeof String.fromCodePoint !== 'undefined'
                ? isoCode
                    .toUpperCase()
                    .replace(/./g, char =>
                        String.fromCodePoint(char.charCodeAt(0) + 127397)
                    )
                : isoCode;
        }


        render(): ReactNode {
            return (
                // @ts-ignore
                <WrappedComponent
                    {...this.props}
                    countriesHOC={this.state.countriesHOC}
                    countryToFlag={this.countryToFlag}
                    getLocations={this.getLocations}
                />

            )
        }
    }
}

export default withCountries;
