import React from "react"
import { Field, Form, Formik } from "formik"
import * as YupValidation from "yup"
import { TextInput } from "../../admin-mobile-app/src/Utilities/Utils"
import { Dialog } from "../../studio-store-ecommerce-components/src/Dialog/Dialog"
import UpiPaymentActivateDialogController from "./UpiPaymentActivateDialogController.web"
const configJSON = require("./config.js")

export default class UpiPaymentActivateDialog extends UpiPaymentActivateDialogController {
  render() {
    return (
      <Dialog
        open={this.props.open}
        setOpen={this.props.setOpen}
        onSubmit={this.handleSubmitDialog}
        title={configJSON.upiPaymentIntegration}
        customContent
        okay="Save"
        containerClassName="payment-activate-dialog"
        titleClassName="title"
        ignoreClickAway
      >
        <Formik
          innerRef={this.formikRef}
          initialValues={{
            id: this.props.defaultValues?.id ?? 1,
            upi_id: this.props.defaultValues?.upi_id ?? "",
            merchant_store_name: this.props.defaultValues?.merchant_store_name ?? ""
          }}
          validationSchema={YupValidation.object().shape({
            upi_id: YupValidation.string().required(configJSON.thisFieldIsRequired),
            merchant_store_name: YupValidation.string().required(configJSON.thisFieldIsRequired)
          })}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form
              autoComplete="off"
              noValidate
              className="form"
              translate={undefined}
            >
              <Field type="hidden" name="id" />
              <TextInput
                label={configJSON.labelUpiId}
                name="upi_id"
                required
                {...formikProps}
              />
              <TextInput
                label={configJSON.labelUpiMerchantName}
                name="merchant_store_name"
                required
                {...formikProps}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}
