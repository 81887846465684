import { BlockComponent } from "../../../framework/src/BlockComponent"

// Customizable Area Start
import React from "react"
// Customizable Area End

export type Props = {
  id: string
  // Customizable Area Start
  onSubmit: (filterData: any) => void
  classes: any
  dataToPass: any
  orderStatus: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  expandAccordion: boolean[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any
  // Customizable Area End
}

export default class OrderFilterDialogController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: any) {
    super(props)
    this.state = {
      expandAccordion: new Array(3).fill(true),
    }
  }

  // @ts-ignore
  componentDidMount() {
    this.setAccordionToInitialState();
  }

  setAccordionToInitialState = () => {
    this.setState({
      expandAccordion: [true, true, true],
    })
  }

  handleAccordionChange = (itemIndex: number) => () => {
    const expandAccordion = [...this.state.expandAccordion]
    expandAccordion[itemIndex] = !expandAccordion[itemIndex]
    this.setState({
      expandAccordion: expandAccordion,
    })
  }

  applyChanges = (filterData: any) => {
    const { onSubmit } = this.props
    onSubmit({ filteredValue: filterData })
  }
  resetChanges = () => {
    const { onSubmit } = this.props
    onSubmit({
      filteredValue: {
        fromDate: "",
        toDate: "",
        isPlaced: false,
        isDelivered: false,
        isRefunded: false,
        isCancelled: false,
        fromTotal: "",
        toTotal: "",
      },
    })
  }
}
