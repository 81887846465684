import { BlockComponent } from "../../../framework/src/BlockComponent";
import { UpiPayment as UpiPaymentType } from "./Types";

// Customizable Area Start
// Customizable Area End

export type Props = {
  // Customizable Area Start
  data?: UpiPaymentType;
  onClick: (id: number) => void;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  showActivateDialog: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UpiPaymentCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.state = {
      showActivateDialog: false,
    };
  }

  handleClick = () => {
    const { data, onClick } = this.props;
    const { id } = data || {};
    onClick(id || 1);
  };

  handleClipboard = (text?: string|null) => () => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(text ?? "")
  };
}
