Object.defineProperty(exports, '__esModule', {
  value: true
});

exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.activationStatusLinkBase = "https://staging.cloud-marketplace.builder.ai/api/accounts/";
exports.errorPageInitialisation = "Error on page initialization";
exports.errorCardUpdate = "Error on card update";
exports.basic = "Basic ";
exports.errorActivation = "Error on Activation Status Check";
exports.ccAvenuePaymentIntegration = "CCAvenue Integration";
exports.thisFieldIsRequired = "This field is required";
exports.labelMerchantId = "Merchant ID";
exports.labelWorkingKey = "Working Key";
exports.labelAccessCode = "Access Code";
exports.notActive = "Not active";
exports.active = "Active";
exports.ccAvenueTitle = "CCAvenue Payment";
exports.viewDashboard = "View dashboard";
exports.activateAccount = "Activate account";
exports.editAccount = "Edit account";
exports.consoleErrorBase = "Async: Could not copy text: ";
exports.useToGetMessage = "Setup CCAvenue Payment for your transaction.";

