import React, { useState } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import {
  IconButton,
  Button,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";

import "./notfound.css";
type NotFoundPropType = {
    tableContainerClassname: React.CSSProperties;
    titles: Array<string>;
    headCellStyle: React.CSSProperties;
    editIconAlign: "left" | "right" | "inherit" | "center" | "justify" | undefined;
    edit:boolean;
    push:boolean
  };
  const NotFound = (props: NotFoundPropType) => {
    const {
        tableContainerClassname,
      titles,
      headCellStyle,
      editIconAlign,
      edit,
      push,

    } = props;
  return (
    <div className="mt-1">
        <TableContainer component={Paper} style={tableContainerClassname}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
   
              {titles?.map((title: string, index: number) =>
                <TableCell style={headCellStyle} align={'left'} key={index}>
                  {title}
                </TableCell>)}
              {!!push && (
                <TableCell style={headCellStyle} align={"left"} key={titles.length}>
                  Push
                </TableCell>
              )}
              {!!edit && (
                <TableCell style={headCellStyle} align={editIconAlign} key={titles.length + 1}>
                  Edit
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          </Table>
          </TableContainer>
      <div className="trans-fl-pg-inner-wrap">
        <div className="text-center py-5">
          <img
            src={require("./assets/not_found.svg")}
            className="img-fluid yt-transaction-cl-icn"
            width="170"
            height="212"
          />
          <div className="trans-fl-wrap ">
            <p className="trans-fl-ttl my-3">No results found</p>
            <p className="trans-fl-text mb-0">
              Update or remove some of the filters you used
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = (theme: Theme) => createStyles({});
NotFound.defaultProps = {  
    tableContainerClassname: {
    boxShadow: 'none'
  },
  headCellStyle: {
    color: "#676B7E",
    fontSize: "14px",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: 500,
    letterSpacing: "normal",
    lineHeight: "22px",
    backgroundColor: '#F8F9FA'
  },
  editIconAlign: 'center' as 'center',



  edit: false,
  push: false,
  
  };

  export default withStyles(styles)(NotFound);
