// @ts-nocheck
// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import theme from '../../blocks/studio-store-ecommerce-router/src/theme';
import App from '../../blocks/studio-store-ecommerce-router/src/App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { HeaderToastProvider } from '../../blocks/studio-store-ecommerce-components/src/Snackbar/HeaderToastContext';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <>
        <CssBaseline />
        <HeaderToastProvider>
          <App />
        </HeaderToastProvider>
      </>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker();
