import React from "react";
import PaymentController from "./PaymentController.web";
import { PaymentMethods } from "./Utilities/PaymentMethods.web";
import withLoader from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import { Header, PaymentActivateDialog, PaymentCard } from "./Utilities/Utils";
import "./styles.css";
import { withDialog } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
import withHeaderBar from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import UpiPaymentCard from "../../admin-upi-payment/src/UpiPaymentCard.web";
import UpiPaymentActivateDialog from "../../admin-upi-payment/src/UpiPaymentActivateDialog.web";
import CCAvenueCard from "../../admin-ccavenue-payment/src/CCAvenueCard.web";
import CCAvenueActivateDialog from "../../admin-ccavenue-payment/src/CCAvenueActivateDialog.web";
import MuiTab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
const configJSON = require("./config");

export class Payment extends PaymentController {

  renderShippingTabsCompontents(tabIndex: number) {
    if (tabIndex === 0) {
      return (
        <PaymentMethods
          setDialogState={this.openErrorHeaderBar}
          hideLoader={this.props.hideLoader}
          showLoader={this.props.showLoader}
          showHeader={this.props.showHeaderBar}
          paymentMethods={this.state.paymentMethods}
          setPaymentMethods={this.setPaymentMethods}
        />
      );
    } else if (tabIndex === 1) {
      return (
        <div className="payment-gateways-container">
          {
            this.state.paymentMethods.find((paymentMethod) => paymentMethod.id === 2)?.active && (
              <>
                <PaymentCard data={this.state.payment} onClick={this.viewDashboard} paymentStatus={this.state.paymentStatus} />
                {
                  this.state.countryData?.country_code === "in" && (
                    <CCAvenueCard data={this.state.ccAvenuePayment} onClick={this.viewCCAvenueModal} />
                  )
                }
              </>
            )
          }
          {
            this.state.paymentMethods.find((paymentMethod) => paymentMethod.id === 3)?.active && (
              <UpiPaymentCard data={this.state.upiPayment} onClick={this.viewUPIModal} data-testid="upi-payment-card" />
            )
          }
        </div>
      );
    }
  }

  render() {
    const { payment, showActivateDialog, pageInitialized, view, upiPayment, showUPIActivateDialog, showCCavenueActivateDialog, ccAvenuePayment } = this.state;
    if (!pageInitialized) {
      return null;
    }

    const isPaymentGatewayTabVisible = (
      this.state.paymentMethods.find((paymentMethod) => paymentMethod.id === 2)?.active ||
      this.state.paymentMethods.find((paymentMethod) => paymentMethod.id === 3)?.active
    );

    return (
      <div className="payment">
        <Header title={configJSON.payment} />
        <div className="content">
          <div className="payment_tabs" style={{ flex: 1 }}>
            <Tabs
              value={view}
              onChange={this.handleChange}
              indicatorColor="primary"
              textColor="primary"
              className="tabFlexContainer tabsroot"
            >
              <MuiTab
                className={
                  view === 0 ? "tabIconWrapperSelected tablabelIcon tabroot" : "tabIconWrapper tablabelIcon tabroot"
                }
                label={configJSON.paymentMethods}
              />
              {
                isPaymentGatewayTabVisible && (
                  <MuiTab
                    className={
                      view === 1 ? "tabIconWrapperSelected tablabelIcon tabroot" : "tabIconWrapper tablabelIcon tabroot"
                    }
                    label={configJSON.paymentGateway}
                  />
                )
              }
            </Tabs>
          </div>
          {this.renderShippingTabsCompontents(view)}
        </div>
        <PaymentActivateDialog
          open={showActivateDialog}
          setOpen={this.setActivateDialog}
          defaultValues={payment}
          onSubmit={this.updatePayment}
        />
        <UpiPaymentActivateDialog
          open={showUPIActivateDialog}
          setOpen={this.setUPIActivateDialog}
          defaultValues={upiPayment}
          onSubmit={this.updateUPIPayment}
          data-testid="upi-payment-dialog" 
        />
        <CCAvenueActivateDialog
          open={showCCavenueActivateDialog}
          setOpen={this.setCCAvenueActivateDialog}
          defaultValues={ccAvenuePayment}
          onSubmit={this.updateCCAvenuePayment}
        />
      </div>
    );
  }
}

export default withDialog(withLoader(withHeaderBar(Payment)));
