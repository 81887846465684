// @ts-nocheck
import * as React from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import MuiTextField,{TextFieldProps as MuiTextFieldProps} from "@material-ui/core/TextField";

const TextFieldWithStyle = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  width,
  margin = "dense",
  isBorderRadius = true,
  ...props
}: MuiTextFieldProps) => {
  return (
    <MuiTextField
      {...props}
      classes={{
        root : width ? classes.rootfullwidth : classes.root,

      }}
      variant="outlined"
      margin={props.margin}
      InputLabelProps={{
        ...InputLabelProps, shrink: true, classes: {
          asterisk: classes?.astriklabel,
          outlined: classes?.outlinedLabel,
          shrink: classes?.shrink,
          root: classes?.lableroot,
          error:classes?.errorLabel
        },
      }}
      InputProps={{
        ...InputProps,
        classes: {
          input: !props.multiline && classes?.input,
          root: !isBorderRadius ? classes?.inputfieledrootwithoutbroderRadius : classes?.inputfieledroot,
        },
        notched: false,
      }}
      FormHelperTextProps={{
        classes: {
          root: props.helperTextStyle,
        },
      }}
    >
      {children}
    </MuiTextField>
  );
};

const TextField = withStyles((theme) =>
  createStyles({
    root: {
      width: '88%',
    },
    rootfullwidth: {
      width: '100%',
    },
    marginDence: {
      marginTop: '8px',
      marginBottom: '3px',
    },
    inputfieledroot: {
      'label + &': {
        marginTop: 36,
      },
    },
    inputfieledrootwithoutbroderRadius: {
      'label + &': {
        marginTop: 36,
      },
      borderRadius: '0!important',
      background: '#fff',
    },
    input: {
      borderRadius: '3px',
      position: 'relative',
      backgroundColor: '#FFF',
      color: '#000',
      fontWeight: '400',
      fontSize: '14px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '0.8rem 0.5rem',
      width: '100%',
    },
    astriklabel: {
      fontSize: '14px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#000',
    },
    lableroot: {
      fontSize: '12px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#000',
    },
    outlinedLabel: {
      transform: 'translate(3px, 9px) scale(1)!important',
    },
    shrink: {
      transform: 'translate(3px, 9px) scale(1)!important',
    },
    errorLabel:{
      color: '#000 !important',
    }
  })
)(TextFieldWithStyle);

export default TextField;