import React, { useEffect } from "react";
import * as Yup from "yup";
import { Form } from "formik";
import { Card } from "./Card.web";
import {
  CheckboxInput,
  ImageInput,
  RadioInput,
  SelectInput,
  TextInput,
} from "./Inputs.web";
import _ from "lodash";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  AddressValidation,
  PhoneValidation,
  PostalCodeValidation,
} from "../../../admin-validations/src/validation";

const configJSON = require("../config");

export const Schema = Yup.object().shape({
  app_name: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(30, configJSON.maximum_30Characters),
  short_description: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(170, configJSON.maximum_170Characters),
  description: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(4000, configJSON.maximum_4000Characters),
  email: Yup.string()
    .email(configJSON.emailMustBeInValidFormat)
    .required(configJSON.thisFieldIsRequired),
  phone: PhoneValidation("country_name"),
  app_icon: Yup.string().nullable().required(configJSON.appIconIsRequired),
  common_feature_banner: Yup.string()
    .nullable()
    .required(configJSON.commonFeatureBannerIsRequired),
  first_name: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(50, configJSON.maximum_50Characters),
  last_name: Yup.string()
    .required(configJSON.thisFieldIsRequired)
    .max(50, configJSON.maximum_50Characters),
  postal_code: PostalCodeValidation("country_name"),
  address: AddressValidation.required(configJSON.thisFieldIsRequired),
  address_2: AddressValidation,
  tags: Yup.array().of(Yup.string()),
  is_paid: Yup.string(),
  default_price: Yup.number().when("is_paid", {
    is: (is_paid) => is_paid === "true",
    then: Yup.number()
      .required(configJSON.thisFieldIsRequired)
      .min(0.001, configJSON.defaultPriceHasToBeMoreThanZero),
  }),
  country_name: Yup.string(),
  state: Yup.string().when("country_name", {
    is: (countryName) => countryName,
    then: Yup.string().required(configJSON.thisFieldIsRequired),
  }),
  city: Yup.string().when("country_name", {
    is: (countryName) => countryName,
    then: Yup.string().required(configJSON.thisFieldIsRequired),
  }),
  app_categories_attributes: Yup.array(),
});

type Props = {
  formikProps: any;
  showHeaderBar: () => void;
  handleClickNew: (name: any, setFieldValue?: any) => (e: any) => void;
  state: any;
  onCountryChange: (value: any, name: string, setFieldValue: any) => void;
  onStateChange: (value: any, name: string, setFieldValue: any) => void;
  onCityStateChange: (value: any, name: string, setFieldValue: any) => void;
};

export const AppRequirementForm = ({
  formikProps,
  showHeaderBar,
  handleClickNew,
  state,
  onCountryChange,
  onStateChange,
  onCityStateChange,
}: Props) => {
  const handleChangeIsPaid = (value: any, name: any, setFieldValue: any) => {
    setFieldValue("default_price", "");
  };

  return (
    // @ts-ignore
    <Form autoComplete="off" noValidate className="form">
      <Card title={configJSON.mobileAppTitle}>
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="app_name"
          label={configJSON.appName}
          placeholder=""
          required
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="short_description"
          label={configJSON.promotionalText}
          limit={170}
          required
          multiline
          rows={4}
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="description"
          label={configJSON.description}
          limit={4000}
          required
          multiline
          rows={4}
        />
        <ImageInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="app_icon"
          label={configJSON.appIcon}
          height="100px"
          width="140px"
          required
        />
        <ImageInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="common_feature_banner"
          label={configJSON.commonFeatureBanner}
          height="180px"
          required
        />
        <label style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          {Array.from({ length: state.tags_count || 1 }).map((i, index) => (
            <TextInput
              key={`tags[${index}]`}
              {...formikProps}
              showHeaderBar={showHeaderBar}
              name={`tags[${index}]`}
              label={!index ? configJSON.tags : ""}
            />
          ))}
          <button className="add-tag" onClick={handleClickNew("tags")} />
        </label>
      </Card>
      <Card title={configJSON.contactDetails}>
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="website"
          label={configJSON.website}
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="email"
          label={configJSON.email}
          required
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="phone"
          label={configJSON.phone}
          type="number"
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="first_name"
          label={configJSON.firstName}
          required
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="last_name"
          label={configJSON.lastName}
          required
        />
        <div
          className={`country-wrapper ${state.separateCityState ? "separated" : ""
            }`}
        >
          <SelectInput
            {...formikProps}
            required
            onChange={onCountryChange}
            showHeaderBar={showHeaderBar}
            name="country_name"
            label={configJSON.country}
            placeholder={configJSON.enterTheCountryPlaceholder}
            options={state.countryList}
          />
          {state.separateCityState ? (
            <>
              <SelectInput
                {...formikProps}
                required
                showHeaderBar={showHeaderBar}
                onChange={onStateChange}
                name="state"
                label={configJSON.state}
                placeholder={configJSON.enterTheStatePlaceholder}
                options={state.stateList}
              />
              <SelectInput
                {...formikProps}
                required
                autoComplete="off"
                showHeaderBar={showHeaderBar}
                name="city"
                label={configJSON.city}
                placeholder={configJSON.enterTheCityPlaceholder}
                options={state.cityList}
              />
            </>
          ) : (
            <SelectInput
              {...formikProps}
              required
              showHeaderBar={showHeaderBar}
              onChange={onCityStateChange}
              name="state"
              label={configJSON.cityState}
              placeholder={configJSON.enterTheCityStatePlaceholder}
              options={state.stateList}
            />
          )}
          <TextInput
            {...formikProps}
            showHeaderBar={showHeaderBar}
            name="postal_code"
            label={configJSON.postcodeZipPin}
            placeholder={configJSON.enterThePostcodePlaceholder}
            required
          />
        </div>
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="address"
          label={configJSON.addressLine_1}
          placeholder={configJSON.enterTheAddressDetailsPlaceholder}
          required
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="address_2"
          label={configJSON.addressLine_2}
          placeholder={configJSON.enterExtraAddressDetailsPlaceholder}
        />
      </Card>
      <Card title={configJSON.pageUrLs}>
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="privacy_policy_url"
          label={configJSON.privacyPolicyUrl}
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="support_url"
          label={configJSON.supportUrl}
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="marketing_url"
          label={configJSON.marketingUrl}
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="terms_and_conditions_url"
          label={configJSON.termsAndConditionsUrl}
        />
        <SelectInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="target_audience_and_content"
          label={configJSON.targetAudienceAndContent}
          placeholder={configJSON.selectAudiencePlaceholder}
          options={[
            { label: configJSON.label5AndUnder, value: "5 and under" },
            { label: configJSON.label6_8, value: "6-8" },
            { label: configJSON.label9_12, value: "9-12" },
            { label: configJSON.label13_15, value: "13-15" },
            { label: configJSON.label16_17, value: "16-17" },
            { label: configJSON.label18AndOver, value: "18 and under" },
          ]}
        />
      </Card>
      <Card title={configJSON.pricingAndDistribution}>
        <RadioInput
          {...formikProps}
          onChange={handleChangeIsPaid}
          showHeaderBar={showHeaderBar}
          name="is_paid"
          options={[
            { label: configJSON.labelFreeApp, value: "false" },
            { label: configJSON.labelPaidApp, value: "true" },
          ]}
        />
        <DefaultPriceInput
          formikProps={formikProps}
          showHeaderBar={showHeaderBar}
        />
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="distributed_countries"
          label={configJSON.distributedCountries}
        />
        <div className="checkbox-section-wrapper">
          <CheckboxInput
            {...formikProps}
            showHeaderBar={showHeaderBar}
            name="auto_price_conversion"
            label={configJSON.autoPriceConversion}
          />
          <CheckboxInput
            {...formikProps}
            showHeaderBar={showHeaderBar}
            name="android_wear"
            label={configJSON.androidWear}
          />
          <CheckboxInput
            {...formikProps}
            showHeaderBar={showHeaderBar}
            name="google_play_for_education"
            label={configJSON.googlePlayForEducation}
          />
          <CheckboxInput
            {...formikProps}
            showHeaderBar={showHeaderBar}
            name="us_export_laws"
            label={configJSON.usExportLaws}
          />
        </div>
        <TextInput
          {...formikProps}
          showHeaderBar={showHeaderBar}
          name="copyright"
          label={configJSON.copyright}
        />
      </Card>
      <Card title={configJSON.appCategory}>
        {state.app_categories_attributes_count ? (
          <div className="app-category-wrapper">
            {Array.from({
              length: state.app_categories_attributes_count || 0,
            }).map((i, index) => (
              <AppCategoryInputs
                formikProps={formikProps}
                showHeaderBar={showHeaderBar}
                index={index}
              />
            ))}
          </div>
        ) : null}

        <label
          className="add-app-type"
          onClick={handleClickNew(
            "app_categories_attributes",
            formikProps.setFieldValue
          )}
        >
          <img src={require("../assets/add.svg")} alt="add app type" />
          <span>{configJSON.addAppType}</span>
        </label>

        {_.get(formikProps.errors, "app_category") &&
          _.get(formikProps.touched, "app_category") &&
          typeof formikProps.errors.app_category === "string" && (
            <FormHelperText error={true}>
              {_.get(formikProps.errors, "app_category")}
            </FormHelperText>
          )}
      </Card>
      <button type="submit" style={{ display: "none" }} />
    </Form>
  );
};

type AppCategoryInputsProps = {
  formikProps: any;
  showHeaderBar: any;
  index: number;
};

export const AppCategoryInputs = ({
  formikProps,
  showHeaderBar,
  index,
}: AppCategoryInputsProps) => {
  const name = `app_categories_attributes[${index}].`;
  const id = _.get(formikProps.values, name + "id");
  const isDestroyed = !_.get(
    formikProps.values,
    `app_categories_attributes[${index}]`
  );

  const handleDestroy = () => {
    if (!id) {
      formikProps.setFieldValue(
        `app_categories_attributes[${index}]`,
        undefined
      );
    }
  };

  return isDestroyed ? null : (
    <>
      <CheckboxInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "_destroy"}
        label={id ? configJSON.deleteAppType : configJSON.remove}
        onChange={handleDestroy}
      />
      <SelectInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "app_type"}
        label={configJSON.appType}
        options={[
          { label: configJSON.labelAndroid, value: "android" },
          { label: configJSON.labelIOs, value: "ios" },
        ]}
      />
      <ImageInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "feature_graphic"}
        label={configJSON.featureGraphic}
        height="100px"
        width="200px"
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "product_title"}
        label={configJSON.productTitle}
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "app_category"}
        label={configJSON.appCategory}
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "review_username"}
        label={configJSON.reviewUsername}
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "review_password"}
        label={configJSON.reviewPassword}
        type="password"
      />
      <TextInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "review_notes"}
        label={configJSON.reviewNotes}
      />
      <ImageInput
        {...formikProps}
        showHeaderBar={showHeaderBar}
        name={name + "attachments_attributes"}
        label={configJSON.screenshots}
        multiple
        width="200px"
        height="100px"
      />
      <Divider />
    </>
  );
};

export const DefaultPriceInput = ({ formikProps, showHeaderBar }: any) => {
  const paid = formikProps.values.is_paid === "true";

  useEffect(() => {
    if (!paid) {
      formikProps.setFieldValue("default_price", "");
    }
  }, [paid]);

  return (
    <div className="default-price-wrapper">
      <TextInput
        {...formikProps}
        style={{ width: "254px" }}
        showHeaderBar={showHeaderBar}
        name="default_price"
        label={configJSON.defaultPrice}
        disabled={!paid}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img src={require("../assets/lock.svg")} alt="" />
            </InputAdornment>
          ),
        }}
        type="number"
      />
    </div>
  );
};

export const Divider = () => (
  <div
    style={{
      borderBottom: "1px solid #ECEDF0",
      height: "1px",
    }}
  />
);
