import React from "react";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import EmailTemplatesController from "./EmailTemplatesController";
import { Tab as TabType } from "./Types";
import Categories from "./Categories";
import { Scrollbars } from "react-custom-scrollbars";
import "./styles.css";
import withSnackBar from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";
import { withDialog } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
import withLoader from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import withHeaderBar from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";

export class EmailTemplates extends EmailTemplatesController {
  render() {
    const { selectedTab, headerTabs, emailSettings } = this.state;
    return (
      <Scrollbars>
        <div className="email-templates">
          <Header onCreateClick={this.handleCreateClick}>
            <div className="tabs-wrapper">
              <Tabs
                headerTabs={headerTabs}
                onTabChange={this.handleTabChange}
                value={selectedTab}
              />
            </div>
          </Header>

          {headerTabs.length ? (
            <Categories
              categories={headerTabs[selectedTab].categories}
              emailSettings={emailSettings}
              onActiveChange={this.handleActiveChange}
              onEditClick={this.handleEditClick}
            />
          ) : null}
        </div>
      </Scrollbars>
    );
  }
}

export const Header = ({
  onCreateClick,
  children,
}: {
  onCreateClick: any;
  children: React.ReactNode;
}) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <span className="header-title">Email Templates</span>
        <div className="header-buttons">
          <button className="header-create-button" onClick={onCreateClick}>
            Create new email
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

interface TabProps {
  onTabChange?:
  | ((event: React.ChangeEvent<{}>, value: any) => void)
  | undefined;

  value: any;
  headerTabs: TabType[];
}

const Tabs = ({ onTabChange, value, headerTabs }: TabProps) => {
  return (
    <MuiTabs
      classes={{
        root: "tabs-root",
        indicator: "tabs-indicator",
        flexContainer: "tabs-flex-container",
      }}
      value={value}
      onChange={onTabChange}
    >
      {headerTabs.map((headerTab) => (
        <MuiTab
          key={headerTab.tab_name}
          classes={{
            root: "tabs-root",
            labelIcon: "tab-label-icon",
            selected: "tab-label-selected",
          }}
          label={`${headerTab.tab_name} (${headerTab.count})`}
          data-testid={"email-tab-" + headerTab.tab_name}
        />
      ))}
    </MuiTabs>
  );
};

export default withLoader(
  withSnackBar(withHeaderBar(withDialog(EmailTemplates)))
);
