import React from "react";

export interface MobileIconProps {
  color?: string;
  size?: number;
  className?: string;
  style?: any;
}

function MobileIcon(props: MobileIconProps) {
  const { color, size, className, style } = props;
  return (
    <span style={style} className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.5 0.500976C11.1569 0.500977 12.5 1.84412 12.5 3.50098L12.5 12.501C12.5 14.1578 11.1569 15.501 9.5 15.501L6.5 15.501C4.84314 15.501 3.5 14.1578 3.5 12.501L3.5 3.50098C3.5 1.84412 4.84315 0.500976 6.5 0.500976L9.5 0.500976Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.5 12.501L12.5 12.501"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
}

MobileIcon.defaultProps = {
  color: "#9B9FB1",
  size: 16,
};

export default MobileIcon;
