// @ts-nocheck
import OrdersController from "./OrdersController.web";

import * as React from "react";
import withLoader from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import {
  withStyles,
  Theme,
  createStyles,
  Button,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import withHeaderBar from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";

import Tabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import PopupProdiver from "../../studio-store-ecommerce-components/src/Dialog/DialogContext";
import TableComponent from "../../studio-store-ecommerce-components/src/TableComponent/TableComponent";
import { Scrollbars } from "react-custom-scrollbars";
import "./order.css";
import SearchAndFilter from "../../studio-store-ecommerce-components/src/SearchAndFilter/SearchAndFilter.web";
import OrderFilter from "./OrderFilterDialog.web";
import Pagination from "../../studio-store-ecommerce-components/src/Pagination/Pagination";
import { withDialog } from '../../studio-store-ecommerce-components/src/Dialog/withDialog';
import NotFoundWeb from "../../studio-store-ecommerce-components/src/NotFound/NotFound.web";

const configJSON = require("./config");

const titles =  configJSON.orderTitles;
export class Orders extends OrdersController {

  renderCustomerName = (rowData) => {
    return (
      <p className="customerName">
        {rowData?.attributes?.account?.data?.attributes?.full_name}
      </p>
    );
  };
  renderOrderStatusName = (rowData) => {
    return <p className="orderStatus">{rowData?.attributes?.status}</p>;
  };
  renderPdfIcon = () => {
    return (
      <IconButton
        className="icon-download"
        aria-label="download"
        size={"small"}
      />
    );
  };
  renderEditIcon = () => {
    return (
      <IconButton
        className="icon-pencil"
        aria-label="download"
        size={"small"}
      />
    );
  };
  renderColumn = (rowData, keyName) => {
    return <span>{rowData?.attributes[keyName]}</span>;
  };

  render() {
    const { classes } = this.props;
    const { orderList, totalOrders, showEmpty } = this.state;

    if (this.state.isFirstLoad) {
      return null
    }

    return (
      <PopupProdiver>
        <Scrollbars>
          <Grid
            container
            className={classes?.orderContainer}
            justifyContent="center"
            spacing={1}
            id="tableContainer"
          >
            <Grid xs={12} item>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                  <Typography className={classes?.pageTitle}>
                    {configJSON.orders}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <Button
                    data-testid="btn-download-csv"
                    variant="text"
                    color="primary"
                    onClick={this.downloadCSVReport.bind(this)}
                  >
                    {configJSON.downloadCsv}
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Tabs
                    classes={{
                      root: classes?.tabsroot,
                      flexContainer: classes?.tabFlexContainer,
                    }}
                    value={this.state.orderStatus}
                    onChange={this.handleOrderStatusChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <MuiTab
                      classes={{
                        root: classes?.tabroot,
                        wrapper: classes?.tabIconWrapper,
                        labelIcon: classes?.tablabelIcon,
                      }}
                      label={`${configJSON.all} (${this.state.allOrderCount})`}
                      value="all"
                    />
                    <MuiTab
                      classes={{
                        root: classes?.tabroot,
                        wrapper: classes?.tabIconWrapper,
                        labelIcon: classes?.tablabelIcon,
                      }}
                      label={`${configJSON.placed} (${this.state.totalPlacedOrders})`}
                      value="placed"
                    />
                    <MuiTab
                      classes={{
                        root: classes?.tabroot,
                        wrapper: classes?.tabIconWrapper,
                        labelIcon: classes?.tablabelIcon,
                      }}
                      value="delivered"
                      label={`${configJSON.delivered} (${this.state.totalDilveredOrder})`}
                    />

                    <MuiTab
                      classes={{
                        root: classes?.tabroot,
                        wrapper: classes?.tabIconWrapper,
                        labelIcon: classes?.tablabelIcon,
                      }}
                      value="cancelled"
                      label={`${configJSON.cancelled} (${this.state.totalCanceledOrder})`}
                    />
                    <MuiTab
                      classes={{
                        root: classes?.tabroot,
                        wrapper: classes?.tabIconWrapper,
                        labelIcon: classes?.tablabelIcon,
                      }}
                      value="refunded"
                      label={`${configJSON.refunded} (${this.state.totalRefundedOrder})`}
                    />
                  </Tabs>
                </Grid>
                <Grid item xs={12} className="mt-24">
                  <SearchAndFilter
                    filterText="Filter orders"
                    filterWidth="350px"
                    placeholderText="Search orders"
                    setFilter={this.setFilter}
                    initialFilterValues={this.state.orderFilters}
                    FilterComponent={OrderFilter}
                    showFilterButton
                    searchValue={""}
                    handleSearch={(searchQuery) => this.handleSearch(searchQuery)}
                    orderStatus={this.state.orderStatus}
                    removeSearchValue={this.handleRemoveSearchValue}
                    className="customers-filter-bar"
                  />
                </Grid>
                <Grid item xs={12}>
                  {orderList.length ? (
                    <>
                      <TableComponent
                        data={orderList}
                        edit={true}
                        handleEditClick={this.handleEditClick}
                        handleDownloadClick={this.handleDownloadClick}
                        handleCustomerOnClick={() => { }}
                        titles={titles}
                      />
                      <Pagination
                        count={totalOrders || 0}
                        onChange={this.handlePageChange}
                        itemText='orders'
                        pageSize={this.state.rowPerPage}
                        pageNo={this.state.page}
                      />
                    </>
                  ) : (
                    <NotFoundWeb
                      titles={titles}
                      edit={true} />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Scrollbars>
      </PopupProdiver>
    );

  }
}

const styles = () =>
  createStyles({
    orderContainer: {
      width: "100%",
      padding: "40px 24px",
    },
    boxContainer: {
      background: "#fff",
      margin: "20% 30%",
    },
    tabroot: {
      minWidth: "40px",
    },
    tabrootrightSide: {
      minWidth: "72px",
    },
    tabIconWrapper: {
      flexDirection: "row",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "20px",
      textTransform: "capitalize",
    },
    tablabelIcon: {
      minHeight: "28px",
      padding: 0,
    },
    tabSelected: {
      color: "#6200EA",
    },
    tabsroot: {
      minHeight: "20px",
    },
    tabFlexContainer: {
      display: "flex",
      gap: "16px",
      borderBottom: "1px solid #E8E8E8",
      height: "36px",
    },
    textFieldoRoot: {
      borderRadius: 0,
    },
    pageTitle: {
      fontSize: "28px",
      fontWeight: 500,
      color: "#3C3E49",
      letterSpacing: "-0.02em",
    },
  });
export default withStyles(styles)(withLoader(withHeaderBar(withDialog(Orders))));
