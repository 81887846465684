//@ts-nocheck
import React, { Component } from "react";
import {  withStyles,Theme } from "@material-ui/core";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

// import closeIcon from '../../blocks/LandingPage2/assets/phase2/close.png'
export interface DialogOptions {
  catchOnCancel?: boolean;
  title: string;
  dataToPass: any | null;
  dialogStyle?: React.CSSProperties;
  renderedComponent: React.ReactNode;
  width? : string;
  height? : string;
  withCustomDialog? : boolean;
  disableBackdropClick? : boolean,
  disableEscapeKeyDown? : boolean,
  scroll? : string | boolean
}

export interface DialogProps extends DialogOptions {
  open: boolean;
  onSubmit: (data : any) => void;
  onCancel: () => void;
}

const styles = (theme : Theme) => ({
  root: {
    margin: 0,
    padding: "24px 16px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(2),
  },
  closeImage: {
    height: "1.75rem",
    width: "1.75rem",
  },
  dialogTitle: {
    fontSize: "1.75rem",
    fontWeight: 500,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.21,
    letterSpacing: "-0.02em",
    color: '#3C3E49',
  },
  svgRoot : {
    width : '1.2em',
    height : '1.2em'
  }
});

export const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onCancel, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography component="div" className={classes.dialogTitle}>
        {children}
      </Typography>
      {onCancel ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onCancel}
          size="medium"
        >
           <CloseIcon classes={{root: classes.svgRoot}} ></CloseIcon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const Dialog = withStyles((theme)=>{return {
  dialogPaper: {
    maxWidth: (props) => {
      return props?.width ? props?.width : theme.spacing(100)
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    },
    borderRadius : 10
  }
}})((props) => {
  const { children, classes : ab, onCancel,...other } = props;
  return <MuiDialog onClose={onCancel} {...other}  classes={{paper : ab.dialogPaper}}>
      {children}
  </MuiDialog>
})

export const DialogBox: React.FC<DialogProps> = ({
  open,
  title,
  onSubmit,
  dataToPass = {},
  renderedComponent: RenderedComponent,
  onCancel,
  dialogStyle = {
    borderRadius: '16px',
    border: 'solid 1px #cdd2e0',
  },
  withCustomDialog = false,
  scroll = "body",
  titlePadding,
  ...rest
}) => {
  return (
    <Dialog scroll={scroll}  onCancel={onCancel} open={open} style={{ ...dialogStyle }} {...rest}>
     {!withCustomDialog && <><DialogTitle onCancel={onCancel} style={{padding: titlePadding}}>
        {title}
      </DialogTitle>
      <MuiDialogContent style={{overflow : 'hidden',padding : '0'}}>
      {RenderedComponent && (
        <RenderedComponent
          dataToPass={dataToPass}
          onSubmit={onSubmit}
          onCancel={onCancel}
          {...rest}
        />
      )}
    </MuiDialogContent></>}
    {withCustomDialog && RenderedComponent && (
        <RenderedComponent
          dataToPass={dataToPass}
          onSubmit={onSubmit}
          onCancel={onCancel}
          {...rest}
        />
    )}
    </Dialog>
  );
};

export const DialogContext = React.createContext<
  (options: DialogOptions) => Promise<void>
>(Promise.reject);

const PopupProvider = ({ children }) => {
  const [dialogState, setDialogState] = React.useState<DialogOptions | null>(
    null
  );

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openDialogBox = (options: DialogOptions) => {
    setDialogState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleCancel = () => {
    if (dialogState?.catchOnCancel && awaitingPromiseRef?.current) {
      awaitingPromiseRef.current.resolve();
    }
    setDialogState(null);
  };

  const handleSubmit = (data: any) => {
    if (awaitingPromiseRef?.current) {
      awaitingPromiseRef?.current?.resolve(data);
    }
    setDialogState(null);
  };

  return (
    <DialogContext.Provider value={openDialogBox}>
      {children}
      <DialogBox
        open={Boolean(dialogState)}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        {...dialogState}
      />

    </DialogContext.Provider>
  );
};
export default PopupProvider;