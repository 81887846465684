//@ts-nocheck
import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Checkbox,
  withStyles,
  createStyles,
  Grid,
  FormControlLabel,
  withTheme,
} from "@material-ui/core";
import { Formik, Field, Form, FieldArray } from "formik";
import * as Yup from "yup";
import TextField from "./InputFieldWithFormik.web";
import { withRouter } from "react-router-dom";
import withLoader from "../../studio-store-ecommerce-components/src/HOC/withLoader.Web";
import withSnackBar from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";

import LoginController, { Props } from "./LoginController.web";

import CryptoJS from "crypto-js";
import { SECRET_PASSPHRASE } from "../../../framework/src/Globals";
// Customizable Area End

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Email must be in valid format.")
    .required("This field is required."),
  password: Yup.string()
    // .min(
    //   8,
    //   "Password must contain at least 8 characters, one uppercase, one number and one special character"
    // )
    // .matches(
    //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
    //   "Password must contain at least 8 characters, one uppercase, one number and one special character"
    // )
    // .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("This field is required"),
});

export class Login extends LoginController {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: window.localStorage.getItem("rememberMe")
        ? this.getDecryptedInformation().slice(
          0,
          this.getDecryptedInformation().indexOf(" ")
        )
        : "",
      password: window.localStorage.getItem("rememberMe")
        ? this.getDecryptedInformation().slice(
          this.getDecryptedInformation().indexOf(" ") + 1
        )
        : "",
    };
  }
  handleInput = (event: any) => {
    this.setState({
      [event?.target.name]: event.target.value,
    });
  };

  getDecryptedInformation = () => {
    return CryptoJS.AES.decrypt(
      window.localStorage.getItem("rememberMe"),
      SECRET_PASSPHRASE
    ).toString(CryptoJS.enc.Utf8);
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <Formik
        initialValues={{
          email: this.state.email,
          password: this.state.password,
          isRememberMe: true,
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          if (values.isRememberMe) {
            const encrypted = CryptoJS.AES.encrypt(
              [values.email, values.password].join(" "),
              SECRET_PASSPHRASE
            );
            window.localStorage.setItem("rememberMe", encrypted.toString());
          } else {
            window.localStorage.removeItem("rememberMe");
          }
          this.handleSubmitLogin(values);
        }}
      >
        {(formikProps) => {
          const {
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            isValid,
          } = formikProps;
          return (
            <Form autoComplete="off" noValidate>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  height: theme?.breakpoints.down("md") ? "100%" : "100vh",
                  background: "#f9f8f5",
                }}
              >
                <Grid item md={4} sm={4} xs={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Typography
                        className={classes?.logoHeading}
                        align="center"
                      >
                        {window.localStorage.getItem("store_name") || "Builder Fan Club"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box py={3} style={{ background: "#fff" }}>
                        <Grid container justifyContent="center" spacing={1}>
                          <Grid item xs={10}>
                            <Typography
                              variant="h6"
                              align="left"
                              className={classes?.signinHeading}
                            >
                              Login
                            </Typography>
                          </Grid>

                          <Grid item xs={10}>
                            <Field
                              type="email"
                              component={TextField}
                              onChange={(event) => {
                                if (this.invalidEmailError) this.invalidEmailError = "";
                                handleChange(event);
                              }}
                              error={
                                (errors?.email && touched?.email) ||
                                  this.invalidEmailError
                                  ? true
                                  : false
                              }
                              helperText={
                                this.invalidEmailError
                                  ? "Email is invalid"
                                  : errors?.email && touched?.email
                                    ? errors?.email
                                    : ""
                              }
                              name="email"
                              value={values.email}
                              fullWidth
                              label="Email"
                            />
                          </Grid>

                          <Grid item xs={10}>
                            <Field
                              type="password"
                              component={TextField}
                              onChange={(event) => {
                                if (this.invalidPasswordError) this.invalidPasswordError = "";
                                handleChange(event);
                              }}
                              error={
                                (errors?.password && touched?.password) ||
                                  this.invalidPasswordError
                                  ? true
                                  : false
                              }
                              helperText={
                                this.invalidPasswordError
                                  ? "Password is invalid"
                                  : errors?.password && touched?.password
                                    ? errors?.password
                                    : ""
                              }
                              name="password"
                              value={values.password}
                              fullWidth
                              label="Password"
                            />
                          </Grid>
                          <Grid item md={10} xl={10}>
                            <Grid container alignItems="center">
                              <Grid item xs={6}>
                                <FormControlLabel
                                  classes={{ label: classes?.rememberMe }}
                                  control={
                                    <Checkbox
                                      checked={values.isRememberMe}
                                      color="primary"
                                      size="small"
                                      onChange={handleChange}
                                      name="isRememberMe"
                                    />
                                  }
                                  label="Remember me"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Typography
                                  id="forgot-password-field"
                                  onClick={() => {
                                    this.props?.history?.push("/forgotPassword");
                                  }}
                                  color="textPrimary"
                                  className={classes?.forgotPassword}
                                >
                                  Forgot Password?
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item style={{ marginTop: "16px" }} xs={10}>
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              disabled={!isValid}
                              classes={{
                                label: classes?.buttonText,
                              }}
                              fullWidth
                            >
                              Login
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}
const LoginWithRouter = withRouter(Login);
const LoginWithLoader = withLoader(LoginWithRouter);
const LoginWithToast = withSnackBar(LoginWithLoader);

export default withStyles((theme) =>
  createStyles({
    signinHeading: {
      fontSize: "1.125rem",
      lineHeight: 1.33,
      fontWeight: 500,
      letterSpacing: "normal",
      color: "#3C3E49",
    },
    footerText: {
      fontSize: "0.75rem",
      lineHeight: "1.25rem",
      fontWeight: 400,
      letterSpacing: "normal",
      color: "#83889E",
    },
    labelText: {
      fontFamily: "Lato",
      fontSize: "0.75rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      color: "rgba(0, 0, 0, 0.6)",
    },
    buttonText: {
      color: "#3C3E49",
      fontWeight: 500,
      fontSize: "14px",
    },
    forgotPassword: {
      color: "#3C3E49",
      fontWeight: 400,
      textDecoration: "underline",
      fontSize: "0.75rem",
      textAlign: "end",
      cursor: "pointer",
    },
    rememberMe: {
      color: "#3C3E49",
      fontWeight: 400,
      fontSize: "0.75rem",
    },
    logoHeading: {
      color: "#3C3E49",
      fontWeight: 600,
      fontSize: "1.5rem",
      letterSpacing: "0.02px",
    },
  })

)(withTheme(LoginWithToast));
