import { BlockComponent } from "../../../framework/src/BlockComponent";
import { CCAvenuePayment as CCAvenuePaymentType } from "./Types";

// Customizable Area Start
// Customizable Area End

export type Props = {
  // Customizable Area Start
  data?: CCAvenuePaymentType;
  onClick: (id: number) => void;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  showActivateDialog: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CCAvenueCardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.state = {
      showActivateDialog: false
    };
  }

  handleClick = () => {
    const { data, onClick } = this.props;
    const { id } = data || {};
    onClick(id || 1);
  };

  handleClipboard = (text?: string|null) => () => {
    navigator?.clipboard?.writeText?.(text ?? "")
  };
}
