//@ts-nocheck
import { withStyles, createStyles } from "@material-ui/core";
import React from "react";
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";

export interface TextFieldProps
  extends FieldProps,
  Omit<MuiTextFieldProps, "name" | "value" | "error"> { }

export function fieldToTextField({
  disabled,
  field: { ...field },
  form: { touched, errors },
  helperText,
  ...props
}: TextFieldProps): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError : helperText,
    disabled: disabled,
    ...field,
    ...props,
  };
}

const InputFieldWithFormik = ({
  children,
  InputLabelProps = {},
  InputProps = {},
  classes,
  margin = "none",
  ...props
}: TextFieldProps) => {
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      {...props}
      variant="outlined"
      margin="dense"
      InputLabelProps={{
        ...InputLabelProps, shrink: true, classes: {
          asterisk: classes?.astriklabel,
          outlined: classes?.outlinedLabel,
          shrink: classes?.shrink,
          root: classes?.lableroot
        }
      }}
      InputProps={{
        ...InputProps,
        classes: {
          input: !props.multiline && classes?.input,
          root: classes?.inputfieledroot,
        },
        notched: false,
      }}

    >
      {children}
    </MuiTextField>
  );
};
export default withStyles((theme) =>
  createStyles({
    inputfieledroot: {
      'label + &': {
        marginTop: 30,
      }
    },
    input: {
      borderRadius: '3px',
      position: 'relative',
      backgroundColor: '#FFF',
      fontSize: '16px',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.19,
      letterSpacing: 'normal',
      padding: '0.4rem 0.4rem',
    },
    astriklabel: {
      fontSize: '14px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#000000'
    },
    lableroot: {
      fontSize: '0.75rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.14,
      letterSpacing: 'normal',
      color: '#000000'
    },
    outlinedLabel: {
      transform: 'translate(3px, 9px) scale(1)!important'
    },
    shrink: {
      transform: 'translate(3px, 9px) scale(1)!important'
    }
  })

)(InputFieldWithFormik);
