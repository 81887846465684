import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
  inputLabelWrapper: {
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "space-between",
  },
  inputLabel: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#000000",
    marginBottom: "8px",
  },
  disabledInputLabel: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#83889E",
    marginBottom: "8px",
  },
  limitLabel: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "20px",
    textAlign: "right",
    color: "#000000",
  },
  resizeable: {
    resize: "vertical",
  },
  selectInput: {},
  imageInput: {},
  selectInputPlaceholder: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    color: "#D0D2DA",
  },
  selectInputRoot: {
    padding: "14px",
  },
  selectInputIcon: {
    color: "black",
  },
  imageUploadSelectedImageWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    position: "relative",
  },
  imageUploadSelectedImage: {
    maxHeight: "180px",
    maxWidth: "25%",
    objectFit: "contain",
  },
  imageUploadMultipleWrapper: {
    display: "grid",
    gap: "20px",
    gridTemplateColumns: "repeat(auto-fill, minmax(155px, calc(20% - 20px)));"
  },
  imageUploadMultipleSelectedImageWrapper: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "space-between",
  },
  imageUploadMultipleSelectedImage: {
    maxHeight: "155px",
    height: "100%",
    objectFit: "contain"
  },
  imageUploadSelectedImageName: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#000000",
    marginLeft: "18px",
    marginTop: "10px",
  },
  imageUploadSelectedImageSize: {
    fontStyle: "normal",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    marginLeft: "16px",
    marginTop: "10px",
  },
  imageUploadSelectedImageRemove: {
    cursor: "pointer",
    marginLeft: "40px",
    height: "fit-content",
  },
  imageUploadMultipleSelectedImageRemove:{
    fontSize: "12px",
    lineHeight: "20px",
    width: "fit-content",
    textDecorationLine: "underline",
    color: "#3c3e49",
    textTransform: "none",
    cursor: "pointer",
  },
  radioGroupInput: { display: "flex", flexDirection: "column", gap: "12px" },
  radioInput: {
    display: "flex",
    gap: "8px",
    cursor: "pointer",
    width: "fit-content",
  },
  radioLabel: {
    fontSize: "12px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#3c3e49",
  },
  checkboxInput: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
    width: "fit-content",
  },
  checkboxLabel: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    color: "#3c3e49",
  },
});
