import React from "react";
import { IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import UpiPaymentCardController from "./UpiPaymentCardController.web";
const configJSON = require("./config.js");
const clipboardIcon = require("./assets/clipboard.svg");
const upiIcon = require("./assets/upi.svg");

export default class UpiPaymentCard extends UpiPaymentCardController {
  render() {
    const { upi_id, merchant_store_name } = this.props.data ?? {};
    const isUPIIdSet = Boolean(upi_id) && upi_id !== "n/a";

    return (
      <div className="payment-card-vertical">
        <div className="card-image">
          <img src={upiIcon} className="payment-card-type" data-testid="img-icon" />
        </div>
        <div className="payment-card-details">
          <div className="payment-card-information">
            {isUPIIdSet ? (
              <div className="payment-card-active">{configJSON.active}</div>
            ) : (
              <div className="payment-card-inactive">{configJSON.notActive}</div>
            )}
            <div className="payment-card-configuration-type" data-testid="title">
              {configJSON.upiPaymentTitle}
            </div>
            {isUPIIdSet ? (
              <>
                <div className="payment-card-card-information">
                  <span className="title text-nowrap">{configJSON.labelUpiId}:</span>
                  <span className="information" title={upi_id ?? ""}>{upi_id}</span>
                  <IconButton className="clipboard">
                    <img src={clipboardIcon} data-testid="img-clipboard" onClick={this.handleClipboard(upi_id)} />
                  </IconButton>
                </div>
                <div className="payment-card-card-information">
                  <span className="title text-nowrap">{configJSON.labelUpiMerchantName}:</span>
                  <span className="information" title={merchant_store_name ?? ""}>{merchant_store_name}</span>
                  <IconButton className="clipboard">
                    <img src={clipboardIcon} data-testid="img-clipboard" onClick={this.handleClipboard(merchant_store_name)} />
                  </IconButton>
                </div>
              </>
            ) : (
              <div className="payment-card-card-not-active">{configJSON.useToGetMessage}</div>
            )}
          </div>
          <Button className="card-button" onClick={this.handleClick} data-testid="activate-button">
            {isUPIIdSet ? configJSON.editAccount : configJSON.activateAccount}
          </Button>
        </div>
      </div>
    );
  }
}
