import { BlockComponent } from "../../../framework/src/BlockComponent";
import { withHeadeActionBarProps } from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withLoader.Web";
import { withToastProps } from "../../studio-store-ecommerce-components/src/HOC/withSnackBar.Web";
import { RouterProps } from "react-router";

import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

interface S {
  stateData: Array<any>;
  citiesData: Array<any>;
  countryData: Array<any>;
  statePrefillId: any;
}

export type Props = RouterProps &
  withHeadeActionBarProps &
  withLoaderProps &
  withToastProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    // Customizable Area End
  };

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EditAddressController extends BlockComponent<
  Props,
  S,
  SS
> {
  getStateDataApiCallId: string = "";
  getCountryDataApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      stateData: [],
      citiesData: [],
      countryData: [],
      statePrefillId: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    await this.getCountries();
  }

  // function due to sonarQube
  handleCountryProps = (countyProps: any) => {
    if (countyProps === "india") {
      return "in";
    }
    if (countyProps === "uk") {
      return "gb";
    } else {
      return countyProps;
    }
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message?.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.parseExpireTokenResponse(responseJson, this.state, this.props)) {
        this.handleApiMessages(apiRequestCallId, responseJson);
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }

  handleApiMessages = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getStateDataApiCallId) {
      if (responseJson?.data) {
  
        let preFillState = responseJson?.data?.find(
          (state: { attributes: { name: any } }) =>
          
            state.attributes.name ===
                  // @ts-ignore
            this.props?.dataToPass?.delivery_addresses?.state
        );
        this.setState({
          ...this.state,
          stateData: responseJson?.data,
          statePrefillId: preFillState?.attributes?.id,
        }, () => {
            localStorage.setItem('stateId', preFillState?.attributes?.id )
        });
      }
    }
    if (apiRequestCallId === this.getCountryDataApiCallId) {
      if (responseJson?.data) {
        this.setState(
          { ...this.state, countryData: responseJson?.data },
          () => {
            // @ts-ignore
            let propsData = this.props.dataToPass?.delivery_addresses?.country;
            let countryProps = this.handleCountryProps(propsData);
            let stateId = responseJson?.data?.find(
              (country: { attributes: { code: string } }) =>
                country.attributes.code === countryProps
            );
            this.handleStateCalls(stateId?.attributes?.id);
          }
        );
      }
    }
  };
  handleStateCalls = (countryId: any) => {
    this.getStates(countryId);
  };

  async getCountries() {
    this.props.showLoader();
    const getCountryData = this.generateRequestMessage(
      `admin/v1/locations/countries`,
      "GET"
    );
    this.getCountryDataApiCallId = getCountryData.messageId;
    runEngine.sendMessage(getCountryData.id, getCountryData);
  }

  getStates(countryId: number = 1) {
    this.props.showLoader();
    const getStatesData = this.generateRequestMessage(
      `admin/v1/locations/countries/${countryId || 1}/states`,
      "GET"
    );
    this.getStateDataApiCallId = getStatesData.messageId;
    runEngine.sendMessage(getStatesData.id, getStatesData);
  }

}
