import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import { Payment } from "./Types";
import { DialogProps } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
import { withHeadeActionBarProps } from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { RouteProps } from "react-router-dom";
import { UpiPayment } from "../../admin-upi-payment/src/Types";
import { CCAvenuePayment } from "../../admin-ccavenue-payment/src/Types";

const configJSON = require("./config.js");

export interface PaymentMethod {
  id: number;
  payment_method: string;
  active: boolean;
  created_at: Date;
  updated_at: Date;
}
export type Props = DialogProps &
  RouteProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  pageInitialized: boolean;
  payment?: Payment;
  upiPayment?: UpiPayment;
  paymentStatus: string;
  upiPaymentStatus: string;
  showActivateDialog: boolean;
  showUPIActivateDialog: boolean;
  [key: string]: any;
  razorpayAccountStatus?: object | null;
  paymentMethods: PaymentMethod[];
  ccAvenuePayment?: CCAvenuePayment;
  showCCavenueActivateDialog: boolean;
  countryData?: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

class PaymentController extends BlockComponent<Props, S, SS> {
  initMessageId: string = "";
  initUPIMessageId: string = "";
  updateMessageId: string = "";
  updateUPIMessageId: string = "";
  activationStatusMessageId: string = "";
  razorPayAccountStatusMessageId: string = "";
  getCCAvenueDetailsId: string = "";
  updateCCAvenueDetailsId: string = "";
  getCountryMessageId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      view: 0,
      pageInitialized: false,
      showActivateDialog: false,
      paymentStatus: "loading",
      upiPaymentStatus: "loading",
      razorpayAccountStatus: null,
      showUPIActivateDialog: false,
      paymentMethods: [],
      showCCavenueActivateDialog: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // this.callGetValidationApi();
    this.initPage();
    // Customizable Area Start

    // Customizable Area End
  }

  initPage = () => {
    const requestMessage = this.generateRequestMessage(configJSON.paymentsAdminURL, configJSON.getMethod);
    this.initMessageId = requestMessage.messageId;
    this.send(requestMessage);
    

    const upiRequestMessage = this.generateRequestMessage(configJSON.upiPaymentsURL, configJSON.getMethod);
    this.initUPIMessageId = upiRequestMessage.messageId;
    this.send(upiRequestMessage);

    const countryMessage = this.generateRequestMessage(
      configJSON.countryDataApiUrl,
      configJSON.getMethod
    );
    this.getCountryMessageId = countryMessage.messageId;
    this.send(countryMessage);

    const ccAvenuePaymentDetails = this.generateRequestMessage(configJSON.ccAvenuePaymentsURL, configJSON.getMethod);
    this.getCCAvenueDetailsId = ccAvenuePaymentDetails.messageId;
    this.send(ccAvenuePaymentDetails);

    this.props.showLoader();
  };

  updatePayment = (data: object) => {
    const requestMessage = this.generateRequestMessage(
      `${configJSON.paymentsAdminURL}/${this.state.payment?.id || 1}`,
      configJSON.putMethod
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
    this.updateMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  updateUPIPayment = (data: object) => {
    const requestMessage = this.generateRequestMessage(
      `${configJSON.upiPaymentsURL}/update_upi`,
      configJSON.putMethod
    );
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
    this.updateUPIMessageId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  getActivationStatus = (accountId: string) => {
    const requestMessage = this.generateRequestMessage(
      `${configJSON.activationStatusLinkBase}${accountId}`,
      configJSON.getMethod,
      {
        Authorization: `${configJSON.basic} ${window.btoa(`local_cat:password`)}`
      }
    );
    this.activationStatusMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  getRazorpaysAccountStatus = () => {
    const requestMessage = this.generateRequestMessage(
      configJSON.razorPayAccountStatusPaymentsAdminURL,
      configJSON.getMethod
    );
    this.razorPayAccountStatusMessageId = requestMessage.messageId;
    this.send(requestMessage);
    this.props.showLoader();
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (this.updateMessageId === apiRequestCallId) {
        this.setState({ pageInitialized: true });
        if (responseJson.errors) {
          return this.handleError(
            this.initMessageId === apiRequestCallId ? configJSON.errorPageInitialisation : configJSON.errorCardUpdate,
            responseJson
          );
        }
        const payment = {
          id: responseJson.data?.attributes?.id || 0,
          configuration_type: responseJson.data?.attributes?.configuration_type || "",
          api_key: responseJson.data?.attributes?.api_key || "",
          api_secret_key: responseJson.data?.attributes?.api_secret_key || "",
          razorpay_account_id: responseJson.data?.attributes?.razorpay_account_id || "",
          razorpay_variables: responseJson.data?.attributes?.razorpay_variables || false
        } as Payment;

        if (payment.razorpay_account_id) {
          this.getActivationStatus(payment.razorpay_account_id);
        }

        this.setState({
          payment,
          showActivateDialog: false
        });
        this.props.hideLoader();
      } else if (this.updateUPIMessageId === apiRequestCallId) {
        this.setState({ pageInitialized: true });
        if (responseJson.errors) {
          return this.handleError(
            this.initMessageId === apiRequestCallId ? configJSON.errorPageInitialisation : configJSON.errorCardUpdate,
            responseJson
          );
        }
        
        const upiPayment = {
          id: responseJson.id || 0,
          upi_id: responseJson.upi_id || "",
          merchant_store_name: responseJson.merchant_store_name || "",
        } as UpiPayment;

        this.setState({
          upiPayment,
          showUPIActivateDialog: false
        });
        this.props.hideLoader();
      } else if (this.activationStatusMessageId === apiRequestCallId) {
        if (this.checkResponseError(responseJson)) {
          this.setState({ paymentStatus: "not_initiated" });
          return this.showError(
            configJSON.errorActivation,
            responseJson,
            this.props.hideLoader,
            this.props.setDialogState
          );
        }
        this.setState({
          paymentStatus: responseJson.data.kyc_status
        });
      } else if (this.initMessageId === apiRequestCallId) {
        this.setState({ pageInitialized: true });
        if (responseJson.errors) {
          return this.handleError(
            this.initMessageId === apiRequestCallId ? configJSON.errorPageInitialisation : configJSON.errorCardUpdate,
            responseJson
          );
        }
        const payment = {
          id: responseJson.data?.attributes?.id || 0,
          configuration_type: responseJson.data?.attributes?.configuration_type || "",
          api_key: responseJson.data?.attributes?.api_key || "",
          api_secret_key: responseJson.data?.attributes?.api_secret_key || "",
          razorpay_account_id: responseJson.data?.attributes?.razorpay_account_id || "",
          razorpay_variables: responseJson.data?.attributes?.razorpay_variables || false
        } as Payment;

        if (payment.razorpay_account_id) {
          this.getActivationStatus(payment?.razorpay_account_id);
        }

        if (responseJson.data?.attributes?.razorpay_variables) {
          this.getRazorpaysAccountStatus();
        }

        this.setState({
          payment,
          showActivateDialog: false
        });
        this.props.hideLoader();
      } else if (this.initUPIMessageId === apiRequestCallId) {
        this.setState({ pageInitialized: true });
        if (responseJson.errors) {
          return this.handleError(
            this.initMessageId === apiRequestCallId ? configJSON.errorPageInitialisation : configJSON.errorCardUpdate,
            responseJson
          );
        }
        const upiPayment = {
          id: responseJson.data?.id || 0,
          upi_id: responseJson.data?.upi_id || "",
          merchant_store_name: responseJson.data?.merchant_store_name || "",
        } as UpiPayment;
        this.setState({
          upiPayment,
          showUPIActivateDialog: false
        });
        this.props.hideLoader();
      } else if (this.razorPayAccountStatusMessageId === apiRequestCallId) {
        if (responseJson) {
          this.setState({ ...this.state, razorpayAccountStatus: responseJson });
        }
        this.props.hideLoader();
      } else if (this.getCCAvenueDetailsId === apiRequestCallId) {
        this.setState({
          ccAvenuePayment: {
            id: responseJson.data?.id || 1,
            merchant_id: responseJson.data?.merchant_id || "",
            access_code: responseJson.data?.access_code || "",
            working_key: responseJson.data?.working_key || "",
          },
          showCCavenueActivateDialog: false
        });
        this.props.hideLoader();
      } else if (this.updateCCAvenueDetailsId === apiRequestCallId) {
        if (responseJson.errors) {
          return this.handleError(configJSON.errorCardUpdate, responseJson);
        }
        this.setState({
          ccAvenuePayment: {
            id: responseJson?.id || 1,
            merchant_id: responseJson?.merchant_id || "",
            access_code: responseJson?.access_code || "",
            working_key: responseJson?.working_key || "",
          },
          showCCavenueActivateDialog: false
        });
        this.props.hideLoader();
      } else if (this.getCountryMessageId === apiRequestCallId) {
        this.setState({ countryData: responseJson?.data?.attributes });
        this.props.hideLoader();
      }
    }
  };

  handleError = (title: string, responseJson: object) => {
    this.showError(title, responseJson, this.props.hideLoader, this.props.setDialogState);
  };

  viewDashboard = (dialogId: number, isActivated: boolean) => {
    if (isActivated) {
    } else {
      this.setActivateDialog(true);
    }
  };

  viewUPIModal = () => {
    this.setUPIActivateDialog(true);
  };

  setActivateDialog = (newState: boolean) => {
    this.setState({
      showActivateDialog: newState
    });
  };

  setUPIActivateDialog = (newState: boolean) => {
    this.setState({
      showUPIActivateDialog: newState
    });
  };

  openErrorHeaderBar = () => {
    this.props.setDialogState(true, {
      title: "Error on save",
      message: "At least one option must be selected",
      confirmBackground: "#FF1744",
      confirmHoverBackground: "rgb(240, 25, 73)",
      hideCancel: true
    });
  };

  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({
      view: newValue
    });
  };

  setPaymentMethods = (paymentMethods: PaymentMethod[]) => {
    this.setState({ paymentMethods });
  };

  
  updateCCAvenuePayment = (data: object) => {
    const requestMessage = this.generateRequestMessage(
      `${configJSON.ccAvenuePaymentsURL}/${this.state.ccAvenuePayment?.id || 1}`,
      configJSON.putMethod
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(data));
    this.updateCCAvenueDetailsId = requestMessage.messageId;
    this.props.showLoader();
    this.send(requestMessage);
  };

  setCCAvenueActivateDialog = (newState: boolean) => {
    this.setState({
      showCCavenueActivateDialog: newState
    });
  };

  viewCCAvenueModal = () => {
    this.setCCAvenueActivateDialog(true);
  };

}

export default PaymentController;
