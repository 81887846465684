import { IBlock } from "../../../../framework/src/IBlock"
import { Message } from "../../../../framework/src/Message"
import { BlockComponent } from "../../../../framework/src/BlockComponent"
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum"
import { runEngine } from "../../../../framework/src/RunEngine"
import { Option } from "../../../admin-mobile-app/src/Utilities/Utils"

// Customizable Area Start
import React from "react"
import { FormikProps } from 'formik'
// Customizable Area End

export type Props = {
  id: string
  // Customizable Area Start
  classes: any
  formRef: React.RefObject<FormikProps<any>>
  initialValues: any
  onSubmit: (packagingData: any) => void
  showHeaderBar: () => void
  packageOptions: Option[],
  country: string,
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PackagingDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)]
    this.state = {}
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }
}
