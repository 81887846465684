import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { withLoaderProps } from "../../studio-store-ecommerce-components/src/HOC/withBrandingSpinner.web";
import { Option, QrCode } from "./Types";
import { withHeadeActionBarProps } from "../../studio-store-ecommerce-components/src/HOC/withHeadeActionBar.Web";
import { DialogProps } from "../../studio-store-ecommerce-components/src/Dialog/withDialog";
import isEqual from "lodash/isEqual";

const configJSON = require("./config");

export type Props = DialogProps &
  withLoaderProps &
  withHeadeActionBarProps & {
    navigation: any;
    id: string;
    // Customizable Area Start
    history: any;
    showHeaderBar: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  initialValues: any;
  page: number;
  tags_count: number;
  app_categories_attributes_count: number;
  separateCityState: boolean;
  countryList: Option[];
  stateList: Option[];
  cityList: Option[];

  qrCodes: QrCode[];
  showQrCodeDialog: boolean;
  selectedQrCodeId: number;

  selectedTab: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

class MobileAppController extends BlockComponent<Props, S, SS> {
  qrCodesMessageId: string = "";
  generateQrCodeMessageId: string = "";
  editQrCodeMessageId: string = "";
  deleteQrCodeItemId: number = 0;
  deleteQrCodeMessageId: string = "";
  initialValuesMessageId: string = "";
  saveChangesMessageId: string = "";
  countryListMessageId: string = "";
  stateListMessageId: string = "";
  cityListMessageId: string = "";
  formRef: React.RefObject<unknown>;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.formRef = React.createRef();
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ActionMessageFromToaster),
    ];

    this.state = {
      initialValues: {
        ...InitialValues,
      },
      page: 1,
      app_categories_attributes_count: 0,
      tags_count: 1,
      separateCityState: false,
      countryList: [],
      stateList: [],
      cityList: [],

      qrCodes: [],
      showQrCodeDialog: false,
      selectedQrCodeId: 0,

      selectedTab: 0,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // this.callGetValidationApi();
    this.initPage();
    // Customizable Area Start

    // Customizable Area End
  }

  initPage = () => {
    this.readAppRequirements();
    this.readQrCodes();
  };

  readAppRequirements = () => {
    this.props.showLoader();
    const initialValuesMessage = this.generateRequestMessage(
      configJSON.readAppRequirementsApiEndpoint,
      configJSON.readAppRequirementsApiMethodType
    );
    this.initialValuesMessageId = initialValuesMessage.messageId;
    this.send(initialValuesMessage);
  };

  readQrCodes = () => {
    const qrCodesMessage = this.generateRequestMessage(
      configJSON.readQrCodesApiEndpoint,
      configJSON.readQrCodesApiMethodType
    );
    this.qrCodesMessageId = qrCodesMessage.messageId;
    this.send(qrCodesMessage);
  };

  handleSaveChanges = (values: any) => {
    this.props.showLoader();
    const requestMessage = this.generateRequestMessage(
      configJSON.saveChangesApiEndpoint,
      configJSON.saveChangesApiMethodType
    );

    const {
      app_icon,
      common_feature_banner,
      default_price,
      tags,
      app_categories_attributes,
      app_categories, // dont send
      ...data
    } = values;

    data.tags = (tags || []).filter((item: any) => !!item);

    if (!data.city) {
      delete data.city;
    }
    if (!data.state) {
      delete data.state;
    }

    if (default_price) {
      data.default_price = default_price;
    } else {
      data.default_price = null;
    }

    const initialValues = this.state.initialValues;
    if (app_icon !== initialValues.app_icon) {
      data.app_icon = app_icon;
    }
    if (common_feature_banner !== initialValues.common_feature_banner) {
      data.common_feature_banner = common_feature_banner;
    }

    if (
      !isEqual(
        app_categories_attributes,
        initialValues.app_categories_attributes
      )
    ) {
      data.app_categories_attributes = app_categories_attributes
        .filter(((category: any, index: any) => {
          if (!category) {
            return false;
          }

          if (!category.id) {
            return true;
          }

          return !isEqual(category, initialValues.app_categories_attributes[index]);
        }) || [])
        .map((category: any) => {
          const {
            default_attachments_attributes,
            attachments_attributes_raw,
            attachments_attributes,
            feature_graphic,
            _destroy,
            ...data
          } = category;
          if (_destroy) {
            data._destroy = "1";
            return data;
          }
          if ((feature_graphic || "").indexOf("://") === -1) {
            data.feature_graphic = feature_graphic;
          }

          const { id } = category;
          const initialImages = id ? default_attachments_attributes || [] : [];

          return {
            ...data,
            attachments_attributes: (attachments_attributes || [])
              .map((item: any, index: number) => {
                const initialImageId = initialImages[index]?.id;
                if (initialImageId) {
                  // we have id, send destroy if image removed
                  if (!item) {
                    return {
                      id: initialImageId,
                      _destroy: "1",
                    };
                  }
                }

                if (!initialImageId) {
                  if (item) {
                    return { image: item };
                  }
                }
              })
              .filter((item: any) => !!item),
          };
        });
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    this.saveChangesMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  getStates = (value: any) => {
    const item = this.state.countryList.find((item) => item.value === value) || {};
    // @ts-ignore
    const countryId = item.id || 0;
    if (!countryId) {
      return;
    }
    const requestMessage = this.generateRequestMessage(
      `${configJSON.getStatesApiEndpoint}/${countryId}/states`,
      configJSON.getStatesApiMethodType
    );
    this.stateListMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  getCities = (value: any) => {
    const item = this.state.stateList.find((item) => item.value === value) || {};
    // @ts-ignore
    const stateId = item.id || 0;
    if (!stateId) {
      return;
    }
    const requestMessage = this.generateRequestMessage(
      `${configJSON.getCitiesApiEndpoint}/${stateId}/cities`,
      configJSON.getCitiesApiMethodType
    );
    this.cityListMessageId = requestMessage.messageId;
    this.send(requestMessage);
  };

  generateQrCode = (data: any) => {
    if (this.state.selectedQrCodeId) {
      const formData = new FormData();
      formData.append("code_type", data.code_type);
      formData.append("url", data.url);

      const requestMessage = this.generateRequestMessage(
        `${configJSON.editQrCodeApiEndpoint}/${this.state.selectedQrCodeId}`,
        configJSON.editQrCodeApiMethodType,
        { "content-type": undefined }
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      this.editQrCodeMessageId = requestMessage.messageId;
      this.send(requestMessage);
    } else {
      const requestMessage = this.generateRequestMessage(
        configJSON.createQrCodeApiEndpoint,
        configJSON.createQrCodeApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      this.generateQrCodeMessageId = requestMessage.messageId;
      this.send(requestMessage);
    }

    this.props.showLoader();
  };

  deleteQrCode = (qrCodeId: number) => {
    const requestMessage = this.generateRequestMessage(
      `${configJSON.deleteQrCodeApiEndpoint}/${qrCodeId}`,
      configJSON.deleteQrCodeApiMethodType
    );
    this.deleteQrCodeItemId = qrCodeId;
    this.deleteQrCodeMessageId = requestMessage.messageId;
    this.send(requestMessage);
    this.props.showLoader();
  };

  receive = (from: string, message: Message) => {
    if (message.id === getName(MessageEnum.ActionMessageFromToaster)) {
      const type = message.getData(
        getName(MessageEnum.ActionMessageFromToasterMessage)
      );
      if (type === "SAVECHANGES") {
        // @ts-ignore
        this.formRef.current && this.formRef.current.handleSubmit();
      }
      if (type === "DISCARDCHANGES") {
        this.readAppRequirements();
      }
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (this.initialValuesMessageId === apiRequestCallId) {
        this.props.hideLoader();
        if (this.checkResponseError(responseJson)) {
          return this.handleError(
            configJSON.errorOnInitialization,
            responseJson
          );
        }
        const app_categories = (
          responseJson.data?.attributes?.app_categories?.data || []
        ).map((item: any) => ({
          ...item.attributes,
          _destroy: false,
          attachments_attributes: item.attributes.screenshots.data.map(
            (subItem: any) => subItem.attributes.url
          ),
          default_attachments_attributes: item.attributes.screenshots.data,
        }));

        const initialValues = {
          ...InitialValues,
          ...(responseJson.data?.attributes || {}),
          default_price:
            responseJson.data?.attributes?.default_price ??
            InitialValues.default_price,
          app_categories_attributes: app_categories,
        };
        initialValues.is_paid =
          initialValues.is_paid === true || initialValues.is_paid === "true"
            ? "true"
            : "false";
        if (typeof initialValues.tags !== "object") {
          initialValues.tags = [initialValues.tags];
        }
        if (initialValues.country_name) {
          this.getStates(initialValues.country_name);
        }

        this.setState(
          {
            initialValues,
            separateCityState: initialValues.country_name === "India",
            tags_count: initialValues.tags.length,
            app_categories_attributes_count: app_categories.length,
          },
          () => {
            // @ts-ignore
            this.formRef.current?.resetForm();
          }
        );

        const countryMessage = this.generateRequestMessage(
          configJSON.getCountriesApiEndpoint,
          configJSON.getCountriesApiMethodType
        );
        this.countryListMessageId = countryMessage.messageId;
        this.send(countryMessage);
        this.props.hideLoader();
      }

      if (this.saveChangesMessageId === apiRequestCallId) {
        this.props.hideLoader();
        if (this.checkResponseError(responseJson)) {
          return this.handleError(configJSON.errorOnSave, responseJson);
        }
        this.props.showHeaderBar({ type: "success" });
        this.readAppRequirements();
      }

      if (this.countryListMessageId === apiRequestCallId) {
        const countryList = (responseJson?.data || []).map((item: any) => ({
          label: (
            <div className="country-image-option">
              <img src={require(`./assets/${item.attributes.name}.svg`)} />
              {item.attributes.name}
            </div>
          ),
          value: item.attributes.name,
          id: item.id,
        }));

        this.setState(
          {
            countryList,
          },
          () => {
            if (this.state.initialValues.country_name) {
              this.getStates(this.state.initialValues.country_name);
            }
          }
        );
      }

      if (this.stateListMessageId === apiRequestCallId) {
        const stateList = (responseJson?.data || []).map((item: any) => ({
          label: item.attributes.name,
          value: item.attributes.name,
          id: item.id,
        }));

        this.setState(
          {
            stateList,
          },
          () => {
            if (this.state.initialValues.state) {
              this.getCities(this.state.initialValues.state);
            }
          }
        );
      }

      if (this.cityListMessageId === apiRequestCallId) {
        const cityList = (responseJson?.data || []).map((item: any) => ({
          label: item.attributes.name,
          value: item.attributes.name,
          id: item.id,
        }));

        this.setState({
          cityList,
        });
      }

      if (this.qrCodesMessageId === apiRequestCallId) {
        const qrCodes = (responseJson?.data || []).map((item: any) => ({
          id: item.attributes.id,
          code_type: item.attributes.code_type,
          url: item.attributes.url,
          updated_at: item.attributes.updated_at,
        }));

        this.setState({ qrCodes });
      }

      if (this.generateQrCodeMessageId === apiRequestCallId) {
        if (this.checkResponseError(responseJson)) {
          this.props.hideLoader();
          return this.handleError(configJSON.qrCodeGenerateError, responseJson);
        }
        const qrCodes = [...this.state.qrCodes, responseJson.data.attributes];
        this.setState({ qrCodes, showQrCodeDialog: false });
        this.props.hideLoader();
      }
      if (this.editQrCodeMessageId === apiRequestCallId) {
        if (this.checkResponseError(responseJson)) {
          this.props.hideLoader();
          return this.handleError(configJSON.qrCodeEditError, responseJson);
        }

        const qrCodes = [...this.state.qrCodes];
        const item =
          qrCodes.find((item) => item.id === this.state.selectedQrCodeId) || {};
        // @ts-ignore
        item.code_type = responseJson.data.attributes.code_type;
        // @ts-ignore
        item.url = responseJson.data.attributes.url;
        // @ts-ignore
        item.updated_at = responseJson.data.attributes.updated_at;

        this.setState({ qrCodes, showQrCodeDialog: false });
        this.props.hideLoader();
      }
      if (this.deleteQrCodeMessageId === apiRequestCallId) {
        this.props.hideLoader();
        if (this.checkResponseError(responseJson)) {
          return this.handleError(configJSON.errorOnDelete, responseJson);
        }
        const qrCodes = [...this.state.qrCodes];
        const codeIndex = qrCodes.findIndex(
          (item) => item.id === this.deleteQrCodeItemId
        );
        qrCodes.splice(codeIndex, 1);
        this.setState({ qrCodes });
      }
      if (this.checkResponseError(responseJson)) {
        // no-catch errors
        this.props.hideLoader();
        return this.handleError(configJSON.somethingWentWrong, responseJson);
      }
    }
  };

  handleError = (title: any, responseJson: any) => {
    this.showError(
      title,
      responseJson,
      this.props.hideLoader,
      this.props.setDialogState
    );
  };

  hideLoader = () => {
    this.props.hideLoader();
  };

  handleTabChange = (event: React.ChangeEvent<{}>, selectedTab: number) => {
    this.setState({ selectedTab });
  };

  handleSubmit = (values: any) => {
    this.handleSaveChanges(values);
  };

  showHeaderBar = (options?: any) => {
    this.props.showHeaderBar({
      ignoreClickAway: true,
      message: "",
      ...(options || {}),
    });
  };

  clearCityStates = () => {
    this.setState({ cityList: [], stateList: [] });
  };

  handleCountryChange = (value: any, name: string, setFieldValue: any) => {
    if (value === "India") {
      this.setState({ separateCityState: true }, this.clearCityStates);
    } else {
      this.setState({ separateCityState: false }, this.clearCityStates);
    }
    this.getStates(value);
    setFieldValue("state", "");
    setFieldValue("city", "");
  };

  handleStateChange = (value: any, name: string, setFieldValue: any) => {
    this.getCities(value);
    setFieldValue("city", "");
  };

  handleCityStateChange = (value: any, name: string, setFieldValue: any) => {
    setFieldValue("city", value);
  };

  handleClickNew = (name: any, setFieldValue?: any) => (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (name === "app_categories_attributes") {
      setFieldValue(
        // @ts-ignore
        `${name}[${this.state[name + "_count"]}]`,
        AppCategoryAttributesInitialValues
      );
    }
    this.setState(
      // @ts-ignore
      (prevState) => ({
        // @ts-ignore
        [name + "_count"]: prevState[name + "_count"] + 1,
      }),
      () => {
        this.showHeaderBar();
        // @ts-ignore
        const count = Number(this.state[name + "_count"]);
        const elementName = `${name}[${count - 1}]`;
        const element = document.getElementsByName(elementName)[0];
        if (element) {
          element.focus();
        }
      }
    );
  };

  showGenerateQrCodeDialog = () => {
    this.setState({
      showQrCodeDialog: true,
      selectedQrCodeId: 0,
    });
  };

  showEditQrCodeDialog = (qrCodeId: number) => {
    this.setState({
      showQrCodeDialog: true,
      selectedQrCodeId: qrCodeId,
    });
  };

  setShowQrCodeDialog = (newState: boolean) => {
    this.setState({
      showQrCodeDialog: newState,
    });
  };
}

const InitialValues = {
  //details
  app_name: "",
  short_description: "",
  description: "",
  app_icon: undefined,
  common_feature_banner: undefined,
  tags: [""],

  //contact details
  website: "",
  email: "",
  phone: "",
  first_name: "",
  last_name: "",
  country_name: "",
  city: "",
  state: "",
  postal_code: "",
  address: "",
  address_2: "",

  //Page URLs
  privacy_policy_url: "",
  support_url: "",
  marketing_url: "",
  terms_and_conditions_url: "",
  target_audience_and_content: "",

  //Pricing and distribution
  is_paid: "false",
  default_price: "",
  distributed_countries: "",
  auto_price_conversion: false,
  android_wear: false,
  google_play_for_education: false,
  us_export_laws: false,
  copyright: "",
  app_categories_attributes: [],
};

export const AppCategoryAttributesInitialValues = {
  _destroy: false,
  app_type: "",
  feature_graphic: null,
  product_title: "",
  app_category: "",
  review_username: "",
  review_password: "",
  review_notes: "",
  attachments_attributes: [],
};

export default MobileAppController;
