import React from "react";

const configJSON = require("../config");

export const SampleDocuments = () => {
  return (
    <div className="app-requirement-documents">
      <div className="title">{configJSON.sampleDocuments}</div>
      <div className="hint">{configJSON.sampleDocumentsHint}</div>
      <a className="link" href={configJSON.sampleDocumentsLink} target="_blank">
        <img src={require("../assets/download.svg")} />
        <span>{configJSON.iOsSampleDocument} </span>
      </a>
      <a
        className="link"
        href={configJSON.sampleDocumentsLink2}
        target="_blank"
      >
        <img src={require("../assets/download.svg")} />
        <span>{configJSON.googlePlaySampleDocument} </span>
      </a>
    </div>
  );
};
