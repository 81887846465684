import React, { createRef } from "react"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { TextInput } from "../../../admin-mobile-app/src/Utilities/Utils"
import { Dialog } from "../../../studio-store-ecommerce-components/src/Dialog/Dialog"
import PaymentActivateDialogController from "./PaymentActivateDialogController.web"
const configJSON = require("../config.js")

export class PaymentActivateDialog extends PaymentActivateDialogController {
  render() {
    const { open, onSubmit, setOpen, defaultValues } = this.props;
    const ref = createRef();

    const handleSubmitDialog = () => {
      // @ts-ignore
      ref.current.handleSubmit();
      return false;
    };

    const handleSubmit = (data: object) => {
      onSubmit(data);
    };

    return (
      <Dialog
        open={open}
        setOpen={setOpen}
        onSubmit={handleSubmitDialog}
        title={
          defaultValues
            ? defaultValues?.configuration_type === "razorpay"
              ? configJSON.razorpayIntegration
              : configJSON.stripeIntegration
            : configJSON.paymentIntegration
        }
        customContent
        okay="Save"
        containerClassName="payment-activate-dialog"
        titleClassName="title"
        ignoreClickAway
      >
        <Formik
          // @ts-ignore
          innerRef={ref}
          initialValues={{
            api_key: defaultValues?.api_key || "",
            api_secret_key: defaultValues?.api_secret_key || "",
            configuration_type: defaultValues?.configuration_type || "razorpay",
          }}
          validationSchema={Yup.object().shape({
            api_key: Yup.string().required(configJSON.thisFieldIsRequired),
            api_secret_key: Yup.string().required(configJSON.thisFieldIsRequired),
          })}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form
              autoComplete="off"
              noValidate
              className="form"
              translate={undefined}
            >
              <TextInput
                label={configJSON.labelApiKey}
                name="api_key"
                required
                {...formikProps}
              />
              <TextInput
                label={configJSON.labelSecretApiKey}
                name="api_secret_key"
                required
                {...formikProps}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}
