import React from 'react'

export interface DesktopIconProps {
  color: string
  size: number
  isActive?: boolean
  className?: string
  style?: any,
}

function DesktopIcon(props: DesktopIconProps) {
  const { color, size, className, style } = props;

  return (
    <span style={style} className={className}>
      <svg
        width={size}
        height={(size * 17) / 16}
        viewBox='0 0 16 17'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.5 15.88H5.5L6 13H10L10.5 15.88V15.88Z'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path d='M4 15.8809H12' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path d='M0.5 11.0801H15.5' stroke={color} strokeLinecap='round' strokeLinejoin='round' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0.5 4.48047C0.5 2.82362 1.84315 1.48047 3.5 1.48047H12.5C14.1569 1.48047 15.5 2.82361 15.5 4.48047V10.0005C15.5 11.6573 14.1569 13.0005 12.5 13.0005H3.5C1.84315 13.0005 0.5 11.6573 0.5 10.0005V4.48047Z'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </span>
  )
}

DesktopIcon.defaultProps = {
  color: '#6200EA',
  size: 16,
}
export default DesktopIcon
