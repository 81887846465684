Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.paymentsAdminURL = `admin/v1/payments`;
exports.paymentMethodsURL = `admin/v1/payment_methods`;
exports.upiPaymentsURL = `admin/v1/upi_payments`;
exports.ccAvenuePaymentsURL = "admin/v1/ccavenue_payments";
exports.countryDataApiUrl = "brand_settings/country_data";
exports.razorPayAccountStatusPaymentsAdminURL = `/admin/v1/razorpays/account_status`;
exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.activationStatusLinkBase = "https://staging.cloud-marketplace.builder.ai/api/accounts/";
exports.errorPageInitialisation = "Error on page initialization";
exports.errorCardUpdate = "Error on card update";
exports.errorOnSave = "Error on save";
exports.basic = "Basic ";
exports.errorActivation = "Error on Activation Status Check";
exports.payment = "Payment";
exports.razorpayIntegration = "Razorpay integration";
exports.thisFieldIsRequired = "This field required";
exports.labelApiKey = "Api Key";
exports.labelSecretApiKey = "Secret Api Key";
exports.notActive = "Not active";
exports.razorpayTitle = "Razorpay";
exports.stripeTitle = "Stripe";
exports.paymentTitle = "Payment";
exports.dummyPassword = "*************";
exports.useToGetMessage = "Use to get online payment seamlessly into your bank account";
exports.viewDashboardLink = "https://dashboard.razorpay.com/signin?screen=sign_in";
exports.consoleErrorBase = "Async: Could not copy text: ";
exports.paymentGateway = "Payment Gateways";
exports.paymentMethods = "Payment Methods";
exports.fieldRequired = "This field is required";
exports.apiKey = "API Key";
exports.apiSecretKey = "API Secret Key";
exports.razorPayIntegration = "RazorPay Integration";
exports.stripeIntegration = "Stripe Integration";
exports.paymentIntegration = "Payment Integration";
exports.active = "Active";
exports.inactive = "Inactive";
exports.razorPay = "RazorPay";
exports.stripe = "Stripe";
exports.user = "User:";
exports.password = "Password:";
exports.stars = "*************";
exports.paymentCardHint = "Use to get online payment seamlessly into your bank account";
exports.viewDashboard = "View Dashboard";
exports.activateAccount = "Activate Account";
exports.editPayment = "Edit Payment";
exports.enablePaymentMode = "Enable/Disable Payment Modes";
exports.cashOnDelivery = "Cash On Delivery (COD)";
exports.onlinePayments = "Online Payments";
