import * as React from 'react';
import './assets/css/style.css';

interface RoundIconBadgeProps {
    className?: string,
    iconName: string,
    color?: 'purple' | 'white' | 'green',
    size?: 'sm' | 'md'
}

export const RoundIconBadge: React.FunctionComponent<RoundIconBadgeProps> = (props) => {
    const { iconName, color, className, size } = props
    const theme = color || 'purple';
    const badgsize = size || 'sm';
    return (
        <div className={`round-icon-badge round-icon-badge--size-${badgsize} round-icon-badge--color-${theme} ${className}`}>
            <i className={`icon-${iconName}`}></i>
        </div>
    );
}