import { FormikProps } from "formik";
import { RefObject, createRef } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";

// Customizable Area Start
import { CCAvenuePayment } from "./Types";
// Customizable Area End

export type Props = {
  // Customizable Area Start
  open: boolean;
  setOpen: (newState: boolean) => void;
  onSubmit: (ccAvenueData: CCAvenuePayment) => void;
  defaultValues?: CCAvenuePayment;
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CCAvenueActivateDialogController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  formikRef: RefObject<FormikProps<CCAvenuePayment>>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.formikRef = createRef();
  }

  handleSubmitDialog = () => {
    this.formikRef.current?.handleSubmit();
    return false;
  };

  handleSubmit = (ccAvenueData: CCAvenuePayment) => {
    this.props.onSubmit(ccAvenueData);
  };
}
