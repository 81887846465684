/* App/Lib/GeneralHelpers.js */
import { Platform } from 'react-native';

declare global {
  interface Window {
      notify: (notificationArray: any[]) => void;
  }
}

export function getOS(): string {
  return Platform.OS;
}
