import React from "react";
import { Field, Form, Formik } from "formik";
import * as YupValidation from "yup";
import { TextInput } from "../../admin-mobile-app/src/Utilities/Utils";
import { Dialog } from "../../studio-store-ecommerce-components/src/Dialog/Dialog";
import CCAvenueActivateDialogController from "./CCAvenueActivateDialogController.web";
const configJSON = require("./config.js");

export default class CCAvenueActivateDialog extends CCAvenueActivateDialogController {
  render() {
    return (
      <Dialog
        open={this.props.open}
        setOpen={this.props.setOpen}
        onSubmit={this.handleSubmitDialog}
        title={configJSON.ccAvenuePaymentIntegration}
        customContent
        okay="Save"
        data-testid="ccavenue-activate-dialog"
        containerClassName="payment-activate-dialog"
        titleClassName="title"
        ignoreClickAway
      >
        <Formik
          innerRef={this.formikRef}
          initialValues={{
            id: this.props.defaultValues?.id ?? 1,
            merchant_id: this.props.defaultValues?.merchant_id ?? "",
            access_code: this.props.defaultValues?.access_code ?? "",
            working_key: this.props.defaultValues?.working_key ?? ""
          }}
          validationSchema={YupValidation.object().shape({
            merchant_id: YupValidation.string().required(configJSON.thisFieldIsRequired),
            access_code: YupValidation.string().required(configJSON.thisFieldIsRequired),
            working_key: YupValidation.string().required(configJSON.thisFieldIsRequired)
          })}
          onSubmit={this.handleSubmit}
          enableReinitialize
        >
          {(formikProps) => (
            <Form autoComplete="off" noValidate className="form" translate={undefined}>
              <Field type="hidden" name="id" />
              <TextInput label={configJSON.labelMerchantId} name="merchant_id" required {...formikProps} />
              <TextInput label={configJSON.labelAccessCode} name="access_code" required {...formikProps} />
              <TextInput label={configJSON.labelWorkingKey} name="working_key" required {...formikProps} />
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}
