import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { Payment as PaymentType } from "../Types";

// Customizable Area Start
// Customizable Area End

export type Props = {
  // Customizable Area Start
  data?: PaymentType;
  onClick: (id: number, isActivated: boolean) => void;
  paymentStatus: string;
  razorpayAccountStatus?: any,
  // Customizable Area End
};

interface S {
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class PaymentCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
  }


  handleClick = () => {
    const { data } = this.props;
    const { id, razorpay_variables } = data || {};
    this.props.onClick(id || 1, razorpay_variables || false);
  };

  handleClipboard = (text: any) => () => {
    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(text)
  };
}
