import * as React from "react";
import {
  withStyles,
  Theme,
  createStyles,
  Grid,
  Button,
  Typography,
  Icon,
  InputAdornment,
} from "@material-ui/core";
import withFilter from "../HOC/WithFilter.web";
import Close from "@material-ui/icons/CloseOutlined";
import Search from "@material-ui/icons/Search";
import TextField from "../TextField/TextField.web";
import FilterProvider from "../FilterPopup/FIlterPopoverContext.web";
import "./customers-filter.css";

export const FilterButton = withFilter((props: any) => {
  const openFilterDialog = (event: any) => {
    props
      .openFilter({
        width: props.filterWidth || "30%",
        renderedComponent: props.FilterComponent,
        title: "Filter",
        anchorEl: event.currentTarget,
        dataToPass: {
          initialFilterValues: props.initialFilterValues,
        },
        disableBackdropClick: false,
        disableEscapeKeyDown: true,
        withCustomDialog: false,
        orderStatus:props.orderStatus
      })
      .then((data: any) => {
        props.setFilter(data?.filteredValue);
      });
  };
  return (
    <Button
      variant="outlined"
      style={{
        whiteSpace: "nowrap",
        maxWidth: "150px",
        minWidth: "fit-content",
        maxHeight: "47.2px",
        textTransform: "capitalize",
        borderRight: "none",
        marginTop: "-5px",
        borderRadius: "3px 0 0 3px",
        height: "47.2px"
      }}
      classes={{
        label: props.classes.filterLabel,
      }}
      onClick={(e) => {
        openFilterDialog(e);
      }}
      endIcon={<Icon className="icon-chevron-down f-10" />}
      fullWidth
    >
      Filter Orders
    </Button>
  );
});

type SearchAndFilterProps = {
  filterText?: string;
  filterWidth?: string;
  setFilter?: (filterData: any) => void;
  initialFilterValues?: any;
  FilterComponent?: any;
  showFilterButton?: boolean;
  searchValue: string;
  handleSearch: (text: string) => void;
  removeSearchValue: () => void;
  classes: any;
  placeholderText: string;
  className?: string;
  orderStatus?:string;
};

const SearchAndFilter = (props: SearchAndFilterProps) => {
  const [search, setSearch] = React.useState(props.searchValue);
  const {
    filterText = "",
    setFilter,
    initialFilterValues = {},
    showFilterButton = false,
    searchValue = "",
    handleSearch,
    removeSearchValue,
    FilterComponent,
    classes,
    placeholderText = "Search",
    className = "",
  } = props;
  return (
    <FilterProvider>
      <div className={className} style={{ display: "flex", flex: 1 }}>
        {showFilterButton && (
          <FilterButton
            filterWidth={props.filterWidth}
            orderStatus={props.orderStatus}
            FilterComponent={FilterComponent}
            setFilter={(data: any) => {
              setFilter?.(data);
            }}
            initialFilterValues={initialFilterValues}
            classes={classes}
          >
            {filterText}
          </FilterButton>
        )}
        <CssTextField
          type="search"
          style={{
            background: "none",
          }}
          classes={{
            root: classes.searchInput,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" >
                <div style={{marginRight: -8, marginTop: -6, zIndex: 1000}}>
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" >
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.69353 12.535C12.4234 11.3748 13.6959 8.22136 12.5357 5.49152C11.3755 2.76168 8.22208 1.4892 5.49225 2.64936C2.76241 3.80951 1.48993 6.96297 2.65008 9.69281C3.81024 12.4226 6.9637 13.6951 9.69353 12.535Z" stroke="#AFB2C0" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.3903 11.3896L15.5556 15.5556" stroke="#AFB2C0" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
              </InputAdornment>
            ),
            endAdornment: searchValue ? (
              <InputAdornment position="end">
                <Close onClick={removeSearchValue} />
              </InputAdornment>
            ) : null,
            classes: { notchedOutline: classes.notchedOutline },
          }}
          variant="outlined"
          placeholder={placeholderText}
          // @ts-ignore
          size="small"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          value={search}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch(search);
            }
          }}
          fullWidth
          // @ts-ignore
          showFilterButton={showFilterButton}
        />
      </div>
    </FilterProvider>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    searchInput: {
      flex: "1",
    },
    pageHeading: {
      fontSize: "28px",
      fontWeight: 500,
      color: "#3C3E49",
      letterSpacing: "-0.02em",
    },
    pageSubHeading: {
      color: "#676B7E",
      fontWeight: 400,
      fontSize: "14px",
    },
    textCenter: {
      textAlign: "center",
    },
    pageContainer: {
      background: "#fff",
      margin: 0,
      position: "absolute",
      top: "40%",
      transform: "translateY(-40%)",
      padding: "48px 0",
    },
    notchedOutline: {
      top: 0,
      borderRadius: "0 3px 3px 0 !important",
    },
    filterLabel: {
      // marginTop: 5,
    },
  });

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#AFB2C0",
        // @ts-ignore
        borderTopLeftRadius: (props) => (props.showFilterButton ? 0 : "3px"),
        // @ts-ignore
        borderBottomLeftRadius: (props) => (props.showFilterButton ? 0 : "3px"),
      },
    },
  },
})(TextField);

export default withStyles(styles)(SearchAndFilter);
