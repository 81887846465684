import React from "react";
import HeaderController from "./HeaderController.web";

export class Header extends HeaderController {
  render() {
    return (
      <div className="header">
        <div className="header-title-wrapper">
          <span className="header-title">{this.props.title}</span>
        </div>
        {this.props.children}
      </div>
    );
  }
}
