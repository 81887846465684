import React from "react";
import { GenerateQrCodeButton } from "../MobileApp.web";

const configJSON = require("../config");

interface HeaderProps {
  children?: JSX.Element;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  showQrCodeButton?: boolean;
  disabled?: boolean;
}

export const Header = ({
  children,
  onClick,
  showQrCodeButton = false,
  disabled
}: HeaderProps) => {
  return (
    <div className="header">
      <div className="header-title-wrapper">
        <span className="header-title">{configJSON.mobileApp}</span>
        <div className="header-buttons">
          {showQrCodeButton && <GenerateQrCodeButton onClick={onClick} disabled={disabled} />}
        </div>
      </div>
      {children}
    </div>
  );
};
